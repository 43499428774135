import { useEffect, useState } from "react";
import { usePlataforma } from "../../../Hook/usePlataforma";
import { Card } from "./Card";
import { Container } from "./styles";
import { ButtonSystem, Flex } from "aplication-yetz";
import { Permissions } from "../../Permission";

export function Combos() {
    const { combos, updateCombo, user } = usePlataforma();
    const { catalogo_combo_id = [] } = user;
    const [itensAtivos, setItensAtivos] = useState<number[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    async function update() {
        try {
            setLoading(true);
            await updateCombo(itensAtivos);
            setLoading(false);
        } catch (error: any) {
            console.log(error);
        }
    }

    function toggleItem(itemId: number) {
        if (itensAtivos.includes(itemId)) {
            setItensAtivos(itensAtivos.filter((item) => item !== itemId));
        } else {
            setItensAtivos([...itensAtivos, itemId]);
        }
    }

    useEffect(() => {
        setItensAtivos(catalogo_combo_id);
    }, [catalogo_combo_id]);

    return (
        <Container>
            <div className="container-cards">
                {combos.map((combo) => (
                    <Card
                        {...combo}
                        toggleItem={toggleItem}
                        itensAtivos={itensAtivos}
                    />
                ))}
            </div>

            <Flex className="btns">
                <Permissions role="usuario.update">
                    <ButtonSystem
                        p="16px 32px"
                        fontSize="xs"
                        onClick={update}
                        loading={loading}
                    >
                        SALVAR
                    </ButtonSystem>
                </Permissions>
            </Flex>
        </Container>
    );
}

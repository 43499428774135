import { Container } from "./styles";

interface ResgatarProps {
    link: string;
    label: string;
}

export function Resgatar(props: ResgatarProps) {
    const { link, label } = props;

    return (
        <Container>
            <button onClick={() => window.open(link)}>{label}</button>
        </Container>
    );
}

import { Box, Text } from "aplication-yetz";
import { Container, RegrasStyles } from "./styled";
import { RegrasDetalheProdutoProps } from "./types";
import { useDetalheCombo } from "../../../Hook/useDetalheCombo";
import { regexTagStyles } from "../../../config/regex";
export function Regras() {
    const { detalheCombo } = useDetalheCombo();

    const arrayRegras: RegrasDetalheProdutoProps[] = [
        {
            titulo: "Regras de Utilização",
            texto: detalheCombo?.detalhes?.replace(regexTagStyles, "") || "",
        },
        {
            titulo: "Descrição",
            texto: detalheCombo?.descricao?.replace(regexTagStyles, "") || "",
        },
        {
            titulo: "Informações Importantes",
            texto:
                detalheCombo?.informacoes_importantes?.replace(
                    regexTagStyles,
                    ""
                ) || "",
        },
        {
            titulo: "Informações Temporárias",
            texto:
                detalheCombo?.informacoes_temporarias?.replace(
                    regexTagStyles,
                    ""
                ) || "",
        },
    ];

    return (
        <Container>
            <RegrasStyles>
                {arrayRegras.map((regra, index) => (
                    <Box>
                        <Text mb="8px" bold={true}>
                            {regra.titulo}
                        </Text>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: regra.texto,
                            }}
                        ></div>
                    </Box>
                ))}
            </RegrasStyles>
        </Container>
    );
}

import { Container } from "./styles";
import carrinhoImg from "../../../assets/sistema/carrinho.svg";
import combosImg from "../../../assets/sistema/combos.svg";
import { TextImg } from "aplication-yetz";
import { useTabs } from "../Hooks";
export function HeaderTabs() {
    const { handleTabs, tabs } = useTabs();

    return (
        <Container className="container-buttons">
            <button
                onClick={() => handleTabs(1)}
                className={`${tabs[0].active ? "active" : ""}`}
            >
                <TextImg img={carrinhoImg} fontSize="mdl" fontWeight="600">
                    Catálogo
                </TextImg>
            </button>
            <button
                onClick={() => handleTabs(2)}
                className={`${tabs[1].active ? "active" : ""}`}
            >
                <TextImg img={combosImg} fontSize="mdl" fontWeight="600">
                    Combos
                </TextImg>
            </button>
        </Container>
    );
}

import { Container } from "./styles";
import imgVoltar from "../../../../assets/sistema/voltar-combo.svg";
import imgPencil from "../../../../assets/sistema/lapis.svg";
import { useCombos } from "../../Hook";

export function Header() {
    const { setState, handleTabs, setModal } = useCombos();

    return (
        <Container>
            <div className="container-header-back">
                <button
                    onClick={() => {
                        setState(null);
                        handleTabs(1);
                    }}
                >
                    <div className="container-img">
                        <img src={imgVoltar} alt="" />
                    </div>
                    <div className="container-text">
                        <p>Voltar</p>
                    </div>
                </button>
            </div>

            <div className="container-btn">
                <div className="container-img">
                    <img src={imgPencil} alt="" />
                </div>
                <small>Saiba como Usar</small>
            </div>

            <div className="container-video">
                <button onClick={() => setModal(true)}>Assistir Vídeo</button>
            </div>
        </Container>
    );
}

import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 28px;
    margin-bottom: 35px;
    border-bottom: 1px solid #cfcfcf;

    .container-combo {
        display: flex;
        align-items: center;
        .container-img {
            width: 68px;
            height: 46px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                object-fit: cover;
            }
        }

        .container-descricao {
            margin-left: 17px;
            width: 200px;
            h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: ${({ theme }) => theme.colors.black};
                margin-bottom: 4px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            p {
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                color: ${({ theme }) => theme.colors.primary};
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .box-add-qtd {
        display: flex;
        align-items: center;
        input {
            width: 75px;
            height: 46px;
            border-radius: 8px;
            border: 1px solid #909191;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            padding: 0px 16px;
            line-height: 28px;
            color: ${({ theme }) => theme.colors.black};
            margin-right: 16px;
        }
    }

    button {
        width: 46px;
        height: 46px;
        border-radius: 8px;
        background-color: ${({ theme }) => theme.colors.primary};
        transition: all 0.3s ease;
        &.remove-item {
            background-color: #eeeeee;
            transition: all 0.3s ease;
        }
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            color: ${({ theme }) => theme.colors.white};
        }
    }
`;

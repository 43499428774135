import styled from "styled-components";

export const Container = styled.div``;

export const LoaderStyles = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ConfirmaCatalogo = styled.div`
    position: sticky;
    bottom: -3rem;
    background-color: #fff;
    height: 114px;
    width: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btns {
        grid-gap: 10px;
        .btn-cancelar {
            color: #930035;
            border: 1px solid #930035;
        }
    }
    .qtds-aprovar {
        grid-gap: 35px;
    }
`;

import { UsePlataformaProvider } from "../../Hook/usePlataforma";
import { DetalhePlataforma } from "../../components/DetalhePlataforma";
import { UseTabsProvider } from "../../components/DetalhePlataforma/Hooks";

export function PageDetalhePlataforma() {
    return (
        <UsePlataformaProvider>
            <UseTabsProvider>
                <DetalhePlataforma />
            </UseTabsProvider>
        </UsePlataformaProvider>
    );
}

import { Header } from "./Header";
import { Card } from "./Card";
import { Container } from "./styles";
import { useCombos } from "../Hook";

export function ComoUsar() {
    const { state } = useCombos();
    const cards = JSON.parse(state?.tutorial_passos || "[]") as string[];

    return (
        <Container>
            <Header />
            {cards.map((card, key) => (
                <Card card={card} index={key + 1} key={key} />
            ))}
        </Container>
    );
}

import { FieldProps } from "formik";
import { useEffect } from "react";
import { Container } from "./styles";
import { Card } from "../card";
import { useDetalheCombo } from "../../../../Hook/useDetalheCombo";

type CombosProps = FieldProps & {};

export function Combos(props: CombosProps) {
    const { produtos, setProdutosIdCombo, detalheCombo } = useDetalheCombo();
    const { form } = props;
    const { setFieldValue } = form;

    useEffect(() => {
        if (detalheCombo?.produtos_variacoes?.length) {
            const produtosId = detalheCombo.produtos_variacoes.map(
                (item) => item.produtos_variacao.id
            );
            setProdutosIdCombo(produtosId);
        }
    }, [detalheCombo.produtos_variacoes, setProdutosIdCombo]);

    return (
        <Container>
            {produtos.map((produto) => (
                <Card
                    {...produto}
                    onUpdate={(values) => {
                        setFieldValue("produto_variacao", values);
                    }}
                />
            ))}
        </Container>
    );
}

import styled from "styled-components";

interface ContainerProps {
    color?: string;
}

export const Container = styled.div<ContainerProps>`
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    padding: 27px 24px 24px 24px;
    width: 100%;
    height: 100%;
    background-color: ${(props) => (props.color ? props.color : "#fff")};

    .container-img {
        margin-bottom: 36px;
    }
    .container-text {
        p {
        }
    }

    @media (max-width: 480px) {
        display: flex;
        height: auto;
        padding-top: 23px;
        padding-bottom: 18px;
        .container-text {
            margin-left: 38px;
        }
        .container-img {
            margin-bottom: 0px;
        }
    }
`;

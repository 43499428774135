import { Table } from "aplication-yetz";
import { Link } from "react-router-dom";
import { column } from "./config";
import { Data } from "./types";
import { ImgContainer } from "./styles";
import { Combo, useCombo } from "../../../Hook/useCombo";
import { ToggleCombo } from "./toggle";

export function TableCombo() {
    const { combos, filter } = useCombo();

    function filterCombo(filtro: string, arr: Combo[]) {
        if (arr.length && filtro !== "") {
            arr = arr.filter((combo) => {
                console.log();
                return (
                    JSON.stringify(combo, ["id", "tipo", "nome", "estimativa"])
                        .toLocaleLowerCase()
                        .includes(filtro.toLocaleLowerCase()) ||
                    JSON.stringify(combo.categorias)
                        .toLowerCase()
                        .includes(filtro.toLowerCase())
                );
            });
            return arr;
        }
        return arr;
    }

    const combosFiltered = filterCombo(filter, combos);

    const data: Data[] = combosFiltered.map((combo) => {
        return {
            img: (
                <Link
                    to={{
                        pathname: `/combos/${combo.id}`,
                        state: { id: combo.id },
                    }}
                >
                    <ImgContainer>
                        <img src={combo.foto_capa} alt="" />
                    </ImgContainer>
                </Link>
            ),
            cod_combo: combo.id,
            tipo_de_combo: combo.tipo,
            nome_do_combo: combo.nome,
            estimativa_estoque: combo.estimativa,
            categoria: combo.categorias
                .map((categoria) => categoria.categoria.nome)
                .join(", "),
            status: <ToggleCombo status={combo.status} id={combo.id} />,
        };
    });

    return <Table data={data} columns={column} />;
}

import { useState } from "react";
import { useCombo } from "../../../../Hook/useCombo";
import { Variacao } from "../../../../Hook/useProduto";
import imgTrash from "../../../../assets/sistema/excluir.svg";
import { formataMoney } from "../../../../util/helper";
import { Container } from "./styled";
import { toast } from "react-toastify";

type CardProps = Variacao & {
    onUpdate: (values: number[]) => void;
};

export function Card(props: CardProps) {
    const { onUpdate } = props;
    const { produtosIdCombo, setProdutosIdCombo } = useCombo();
    const [value, setValue] = useState(0);

    function handleAddProduct() {
        setProdutosIdCombo((prev) => {
            const update = [...prev, props.id];
            const count = update.reduce((acc, productId) => {
                if (productId === props.id) {
                    return acc + 1;
                }
                return acc;
            }, 0);
            onUpdate(update);
            setValue(count);
            return update;
        });
    }

    function handleRemoveProduct() {
        setProdutosIdCombo((prev) => {
            const update = prev.filter((productId) => productId !== props.id);

            onUpdate(update);
            return update;
        });
    }

    return (
        <Container>
            <div className="container-combo">
                <div className="container-img">
                    <img
                        src={props.produto?.foto_capa}
                        alt="Imagem do combo"
                        className="file"
                    />
                </div>

                <div className="container-descricao">
                    <h2 title={props.nome}>{props.nome}</h2>
                    <p title={props.produto?.descricao}>
                        {formataMoney(props.valor_reais)}
                    </p>
                </div>
            </div>

            {produtosIdCombo.includes(props.id) ? (
                <div className="box-add-qtd">
                    <input
                        type="number"
                        value={value}
                        onKeyDown={(e) => e.preventDefault()}
                        onKeyUp={(e) => e.preventDefault()}
                        onChange={(e) => {
                            const newArr = [...produtosIdCombo];
                            const previousValue = value;
                            const currentValue = parseInt(
                                e.target.value.replace(/\D/g, "") || "0"
                            );

                            if (currentValue > previousValue) {
                                if (currentValue > 3) {
                                    toast.error(
                                        "Você não pode adicionar mais de 3 produtos no combo"
                                    );
                                    return;
                                }

                                if (newArr.length > 2) {
                                    toast.error(
                                        "Você não pode adicionar mais de 3 produtos no combo"
                                    );
                                    return;
                                }

                                newArr.push(props.id);
                            } else {
                                const index = newArr.findIndex(
                                    (id) => id === props.id
                                );
                                newArr.splice(index, 1);
                            }

                            setProdutosIdCombo(newArr);
                            onUpdate(newArr);
                            setValue(currentValue);
                        }}
                    />
                    <button
                        type="button"
                        className="remove-item"
                        onClick={handleRemoveProduct}
                    >
                        <img src={imgTrash} alt="" />
                    </button>
                </div>
            ) : (
                <button type="button" onClick={handleAddProduct}>
                    <p>+</p>
                </button>
            )}
        </Container>
    );
}

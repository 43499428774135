import { useRef, useState } from "react";
import { Header } from "./header";
import { Cards } from "./cards";
import { Detalhe } from "./Detalhe";
import { Footer } from "../Footer";

import { Tutorial } from "./tutorial";
import { ModalEmail } from "./modalEmail";
import { ModalWhatsapp } from "./modalWhatsapp";
import { Modal } from "../Modal";
import { Container } from "./styles";
import { Loader } from "../Loader";

interface Voucher {
    date: string | null;
    code: string | null;
    password: string | null;
    descricao: string | null;
    expires_at: string | null;
    foto_capa: string | null;
    nome: string | null;
    selo: string | null;
    tutorial_observacao: string | null;
    tutorial_passos: string | null;
    tutorial_url: string | null;
    validade: string | null;
    valor: string | null;
    slug: string | null;
    codes?: any;
}

interface ValePresenteProps {
    voucher?: Voucher;
    loading: boolean;
    setModalTutorial: (tutorial: boolean) => void;
    sandbox?: boolean;
}

export function MultiplosValePresente(props: ValePresenteProps) {
    const { voucher, loading, setModalTutorial, sandbox } = props;
    const [modal, setModal] = useState(false);
    const componentRef = useRef<any>(null);

    const [modalEmail, setModalEmail] = useState(false);
    const [modalWhatsap, setModalWhatsapp] = useState(false);

    const config = {
        ...voucher,
        onClose: () => setModal(false),
    };

    const configWhats = {
        onClose: () => setModalWhatsapp(false),
    };

    const configEmail = {
        onClose: () => setModalEmail(false),
    };

    return (
        <Container ref={componentRef}>
            {loading && (
                <div className="loader">
                    <Loader height={400} width={400} />
                </div>
            )}
            {voucher ? (
                <>
                    <Header
                        {...voucher}
                        setModal={setModal}
                        setModalTutorial={setModalTutorial}
                    />
                    <Cards {...voucher} />
                    <Detalhe
                        {...voucher}
                        setModalEmail={setModalEmail}
                        setModalWhatsapp={setModalWhatsapp}
                        sandbox={sandbox}
                        currentRef={componentRef}
                    />

                    <Footer />
                </>
            ) : null}

            <Modal component={Tutorial} active={modal} rest={config} />

            <Modal
                component={ModalWhatsapp}
                active={modalWhatsap}
                rest={configWhats}
            />
            <Modal
                component={ModalEmail}
                active={modalEmail}
                rest={configEmail}
            />
        </Container>
    );
}

import { Container } from "./styles";
import imgCopiar from "../../../../assets/sistema/copiar-combo.svg";
import { copy } from "../../../../util/helper";

interface SenhaProps {
    password: string;
}

export function Senha(props: SenhaProps) {
    return (
        <Container>
            <div className="container-text">
                <h3>Senha</h3>
                <p>{props.password}</p>
            </div>

            <button onClick={() => copy(props.password)}>
                <img src={imgCopiar} alt="Copiar" />
            </button>
        </Container>
    );
}

import { Combos } from "../../components/Combos";
import { UseComboProvider } from "../../Hook/useCombo";

export function PageCombos() {
    return (
        <UseComboProvider>
            <Combos />
        </UseComboProvider>
    );
}

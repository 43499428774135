import { Container } from "./styles";
import imgPencil from "../../../../assets/sistema/lapis.svg";
import imgShare from "../../../../assets/sistema/compartilhar.svg";
import { Item } from "../../../../pages/PageValePresente";
import { useCombos } from "../../Hook";
import { toast } from "react-toastify";

export function Compartilhar(props: Item) {
    const { setState, handleTabs } = useCombos();

    async function shareUrl() {
        if ("canShare" in navigator) {
            try {
                await navigator.share({
                    title: "Vale Presente",
                    text: "Vale Presente",
                    url: window.location.href,
                });
            } catch (error) {
                toast.error("Erro ao compartilhar");
                console.error("Error sharing:", error);
            }
        } else {
            toast.error("Navegador não suporta compartilhamento");
        }
    }

    return (
        <Container>
            <div className="container-btn">
                <div className="container-img">
                    <img src={imgPencil} alt="" />
                </div>
                <button
                    onClick={() => {
                        handleTabs(2);
                        setState(props);
                    }}
                >
                    Saiba como Usar
                </button>
            </div>

            <div className="line"></div>

            <div className="container-btn">
                <div className="container-img">
                    <img src={imgShare} alt="" />
                </div>
                <button onClick={shareUrl}>Compartilhar</button>
            </div>
        </Container>
    );
}

import { Table } from "aplication-yetz";
import { Header } from "./header";
import { Container, ImgContainer } from "./styles";
import { Data } from "./types";
import { column } from "./config";
import { useDetalheCombo } from "../../../Hook/useDetalheCombo";

export function TableDetalhes() {
    const { detalheCombo } = useDetalheCombo();

    const data: Data[] =
        detalheCombo?.produtos_variacoes?.map((item) => {
            const { foto_capa, id, nome } = item.produtos_variacao;
            return {
                img: (
                    <ImgContainer>
                        <img src={foto_capa} alt="" />
                    </ImgContainer>
                ),
                cod_prod: id,
                produto: nome,
                quantidade: "1",
            };
        }) || [];

    return (
        <Container>
            <Header />
            <Table data={data} columns={column} />
        </Container>
    );
}

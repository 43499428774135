import styled from "styled-components";

export const Container = styled.div`
    .container-cards {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 35px;
    }

    .btns {
        grid-gap: 10px;
        .btn-cancelar {
            color: #930035;
            border: 1px solid #930035;
        }
    }
`;

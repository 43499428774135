import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../services";
import { filterTutorial } from "../../util/helper";
import { Header } from "./header";
import { SubHeader } from "./subHeader";
import { Card } from "./cards";
import { Footer } from "./footer";
import { CardsStyles } from "./styles";

import { Modal } from "../Modal";
import { ModalTutorial } from "./modal";
import { ModalVideo } from "./video";
import { Container } from "./styles";
import { toast } from "react-toastify";

export interface Tutorial {
    id: number;
    nome: string;
    foto_capa: string;
    tutorial_passos: string;
    tutorial_observacao: string;
    tutorial_url: string;
    categoria: string;
    selo_texto: string;
    slug: string;
    template_voucher_id: number;
    selo: number;
    descricao: string;
    informacoes_importantes: string;
    detalhes: string;
    external_link: string;
    external_link_label: string;
    variacao: number;
    produto_id: number;
    cupom?: number;
}

export interface Variacao {
    code: string;
    date: string;
    descricao: string;
    detalhes: string;
    expires_at: null;
    external_link: string;
    foto_capa: string;
    informacoes_importantes: string;
    nome: string;
    password: string;
    produto_validade: number;
    selo: number;
    selo_texto: string;
    slug: string;
    template: string;
    tutorial_observacao: string;
    tutorial_passos: string;
    tutorial_url: string;
    validade: number;
    variacao: number;
}

export interface HistoryProps {
    id?: string;
}

export function PassoAPasso() {
    const history = useHistory<HistoryProps>();
    const [tutorias, setTutorias] = useState<Tutorial[]>([]);
    const [dados, setDados] = useState<Tutorial>({} as Tutorial);
    const [video, setVideo] = useState<string>("");
    const [link, setLink] = useState<string>("");
    const [imgVoucher, setImgVoucher] = useState<string>("");
    const [tutorialObservacao, setTutorialObservacao] = useState<string>("");
    const [tutorial, setTutorial] = useState<string>("");
    const [modalVideo, setModalVideo] = useState<boolean>(false);
    const [filtro, setFiltro] = useState<string>("");
    const [modalTutorial, setModalTutorial] = useState<boolean>(false);
    const [produtoId, setProdutoId] = useState<number>();

    let getId =
        window.location.pathname.replace(/\/passo-a-passo\/?/, "") ||
        history.location.pathname.replace(/\/passo-a-passo\/?/, "") ||
        "ifood";

    const id = getId.includes("promo") ? "ifood" : getId;

    const arrCard = tutorias.map((item) => {
        const {
            nome,
            categoria,
            foto_capa,
            tutorial_passos,
            tutorial_url,
            tutorial_observacao,
            external_link,
        } = item;
        return {
            nome,
            categoria,
            foto_capa,
            tutorial_passos,
            tutorial_url,
            tutorial_observacao,
            external_link,
        };
    });

    const arrFilter = filterTutorial(arrCard, filtro);

    const configProduto = {
        id,
        dados,
        title: "Produtos",
        video: video ? video : "",
        tutorial: tutorial ? tutorial : "[]",
        img: imgVoucher,
        tutorialObservacao: tutorialObservacao,
        link: link,
        produto_id: produtoId,
        onClose: () => {
            if (id) {
                return false;
            } else {
                setModalTutorial(false);
                setModalVideo(false);
                setTutorial("");
                setVideo("");
                setImgVoucher("");
                setTutorialObservacao("");
            }
        },
        openModalVideo: () => {
            setModalTutorial(false);
            setModalVideo(true);
        },
    };

    const configVideo = {
        video: video ? video : "",
        modalVideo: modalVideo,
        closeModal: () => {
            if (id) {
                setModalTutorial(true);
                setModalVideo(false);
            } else {
                setModalTutorial(false);
                setModalVideo(false);
                setTutorial("");
                setVideo("");
                setImgVoucher("");
                setTutorialObservacao("");
            }
        },
        // video: "81db6ab30a",
    };

    function clickOutModal(e: React.MouseEvent<HTMLElement>) {
        const element = (e.target as HTMLElement).dataset;

        if (element?.closemodal) {
            if (id) {
                setModalTutorial(true);
                setModalVideo(false);
            } else {
                setModalTutorial(false);
                setModalVideo(false);
                setTutorial("");
                setVideo("");
                setImgVoucher("");
                setTutorialObservacao("");
            }
        }
    }

    useEffect(() => {
        async function getTutorial() {
            try {
                const { data } = await api.get<Tutorial[]>("/passo-a-passo");
                setTutorias(data);
            } catch (error) {
                toast.error("Erro ao buscar tutoriais");
                throw new Error("Erro ao buscar tutoriais");
            }
        }

        getTutorial();
    }, []);

    useEffect(() => {
        if (id && tutorias.length) {
            const initialTutorial = tutorias.filter(
                (tutorial) => tutorial.slug === id
            )[0];

            if (initialTutorial && Object.values(initialTutorial).length) {
                setTutorial(initialTutorial.tutorial_passos);
                setVideo(initialTutorial.tutorial_url);
                setImgVoucher(initialTutorial.foto_capa);
                setTutorialObservacao(initialTutorial.tutorial_observacao);
                setProdutoId(initialTutorial.id);
                setModalTutorial(true);
                setDados(initialTutorial);
            }
        }
    }, [id, tutorias]);

    return (
        <Container>
            {!id ? (
                <>
                    <Header />
                    <SubHeader setFiltro={setFiltro} />
                    <div className="container">
                        <CardsStyles>
                            {arrFilter.map((item) => (
                                <Card
                                    {...item}
                                    setModalTutorial={setModalTutorial}
                                    setTutorial={setTutorial}
                                    setVideo={setVideo}
                                    setImgVoucher={setImgVoucher}
                                    setLink={setLink}
                                    setTutorialObservacao={
                                        setTutorialObservacao
                                    }
                                />
                            ))}
                        </CardsStyles>
                    </div>

                    <Footer />
                </>
            ) : null}

            <Modal
                component={ModalTutorial}
                active={modalTutorial}
                rest={configProduto}
                onClick={clickOutModal}
                maxWidthMobile={true}
                hide={id ? true : false}
            />

            <Modal
                onClick={clickOutModal}
                component={ModalVideo}
                active={modalVideo}
                rest={configVideo}
                hide={id ? true : false}
            />
        </Container>
    );
}

import { Box, Flex } from "aplication-yetz";
import styled from "styled-components";

export const Container = styled.div`
    width: 547px;
    padding: 33px 39px 36px 39px;
`;

export const TextAreaStyles = styled(Box)`
    margin-bottom: 32px;
    &:last-child {
        margin-bottom: 0px;
    }
    p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: ${({ theme }) => theme.colors.black};
        padding-left: 18px;
        position: relative;
        &:before {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background-color: ${({ theme }) => theme.colors.primary};
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            border-radius: 50%;
        }
    }
`;

export const ContainerImg = styled(Flex)`
    border-bottom: 1px solid #ececec;
    padding-bottom: 26px;
    margin-bottom: 34px;
    button {
        &.remover-img {
            position: absolute;
            top: 10px;
            right: 10px;
            img {
                width: 15px;
            }
        }
    }
    label[for="file"] {
        cursor: pointer;
    }
    img {
        &.file {
            width: 272px;
            height: 182px;
            border-radius: 5px;
            object-fit: cover;
        }
    }
`;

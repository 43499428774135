import { Variacao } from "../../../../Hook/useProduto";
import imgTrash from "../../../../assets/sistema/excluir.svg";
import { formataMoney } from "../../../../util/helper";
import { Container } from "./styled";
import { useDetalheCombo } from "../../../../Hook/useDetalheCombo";

type CardProps = Variacao & {
    onUpdate: (values: number[]) => void;
};

export function Card(props: CardProps) {
    const { produtosIdCombo } = useDetalheCombo();

    return (
        <Container>
            <div className="container-combo">
                <div className="container-img">
                    <img
                        src={props.produto?.foto_capa}
                        alt="Imagem do combo"
                        className="file"
                    />
                </div>

                <div className="container-descricao">
                    <h2 title={props.nome}>{props.nome}</h2>
                    <p title={props.produto?.descricao}>
                        {formataMoney(props.valor_reais)}
                    </p>
                </div>
            </div>

            {produtosIdCombo.includes(props.id) ? (
                <div className="box-add-qtd">
                    <input
                        type="number"
                        value={
                            produtosIdCombo.filter((prod) => prod === props.id)
                                .length
                        }
                        readOnly
                    />
                    <button type="button" className="remove-item">
                        <img src={imgTrash} alt="" />
                    </button>
                </div>
            ) : (
                <button type="button">
                    <p>+</p>
                </button>
            )}
        </Container>
    );
}

import { Heading, Filter, Flex, ButtonSystem } from "aplication-yetz";
import { ChangeEvent } from "react";
import { Permissions } from "../../Permission";

interface HeaderProps {
    setFilter: (filter: string) => void;
    setModal: () => void;
}

export function Header(props: HeaderProps) {
    const { setFilter, setModal } = props;

    return (
        <Flex
            justifyContent="space-between"
            alignItems="flex-end"
            pr="55px"
            mb="78px"
        >
            <Heading as="h2" fontSize="lg">
                Combos
            </Heading>

            <Filter
                onClick={() => {}}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilter(e.target.value)
                }
            />
            <Permissions role="usuario.index">
                <Flex className="container-btns">
                    <ButtonSystem ml="42px" onClick={setModal}>
                        ADICIONAR COMBO
                    </ButtonSystem>
                </Flex>
            </Permissions>
        </Flex>
    );
}

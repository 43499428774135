import styled from "styled-components";

export const Container = styled.div`
    width: 1062px;
    height: 100vh;
    margin: 0 auto;
    padding-top: 52px;
    display: flex;
    flex-direction: column;
    font-family: "Zen Kaku Gothic New";
    .loader {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .card {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
    }

    @media (max-width: 1280px) {
        width: 90%;
    }

    @media (max-width: 480px) {
        padding-top: 31px;
    }
`;

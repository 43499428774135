import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../services";

import { Modal } from "../Modal";
import { ModalTutorial } from "./modal";
import { ModalVideo } from "./video";
import { Container } from "./styles";
import { toast } from "react-toastify";

export interface Tutorial {
    id: number;
    nome: string;
    foto_capa: string;
    tutorial_passos: string;
    tutorial_observacao: string;
    tutorial_url: string;
    categoria: string;
    selo_texto: string;
    slug: string;

    selo: number;
    descricao: string;
    informacoes_importantes: string;
    detalhes: string;
    external_link: string;
    external_link_label: string;
    variacao: number;
    produto_id: number;
}

export interface Variacao {
    code: string;
    date: string;
    descricao: string;
    detalhes: string;
    expires_at: null;
    external_link: string;
    foto_capa: string;
    informacoes_importantes: string;
    nome: string;
    password: string;
    produto_validade: number;
    selo: number;
    selo_texto: string;
    slug: string;
    template: string;
    tutorial_observacao: string;
    tutorial_passos: string;
    tutorial_url: string;
    validade: number;
    variacao: number;
}

export interface HistoryProps {
    id?: string;
}

export function PassoAPasso() {
    const history = useHistory<HistoryProps>();
    const [tutorias, setTutorias] = useState<Tutorial[]>([]);
    const [dados, setDados] = useState<Tutorial>({} as Tutorial);
    const [video, setVideo] = useState<string>("");
    const [imgVoucher, setImgVoucher] = useState<string>("");
    const [tutorialObservacao, setTutorialObservacao] = useState<string>("");
    const [tutorial, setTutorial] = useState<string>("");
    const [modalVideo, setModalVideo] = useState<boolean>(false);
    const [modalTutorial, setModalTutorial] = useState<boolean>(false);
    const [produtoId, setProdutoId] = useState<number>();

    const getId =
        window.location.pathname.split("/").pop() ||
        history.location.pathname.split("/").pop() ||
        "1";

    const id = getId.replace(/\D/g, "") ? getId.replace(/\D/g, "") : "1";

    const configProduto = {
        id,
        dados,
        title: "Produtos",
        video: video ? video : "",
        tutorial: tutorial ? tutorial : "[]",
        img: imgVoucher,
        tutorialObservacao: tutorialObservacao,
        produto_id: produtoId,
        onClose: () => {
            if (id) {
                return false;
            } else {
                setModalTutorial(false);
                setModalVideo(false);
                setTutorial("");
                setVideo("");
                setImgVoucher("");
                setTutorialObservacao("");
            }
        },
        openModalVideo: () => {
            setModalTutorial(false);
            setModalVideo(true);
        },
    };

    const configVideo = {
        video: video ? video : "",
        modalVideo: modalVideo,
        closeModal: () => {
            if (id) {
                setModalTutorial(true);
                setModalVideo(false);
            } else {
                setModalTutorial(false);
                setModalVideo(false);
                setTutorial("");
                setVideo("");
                setImgVoucher("");
                setTutorialObservacao("");
            }
        },
    };

    function clickOutModal(e: React.MouseEvent<HTMLElement>) {
        const element = (e.target as HTMLElement).dataset;

        if (element?.closemodal) {
            if (id) {
                setModalTutorial(true);
                setModalVideo(false);
            } else {
                setModalTutorial(false);
                setModalVideo(false);
                setTutorial("");
                setVideo("");
                setImgVoucher("");
                setTutorialObservacao("");
            }
        }
    }

    useEffect(() => {
        async function getTutorial() {
            try {
                const { data } = await api.get<Tutorial[]>("/passo-a-passo");
                setTutorias(data);
            } catch (error) {
                toast.error("Erro ao buscar tutoriais");
                console.log(error);
            }
        }

        getTutorial();
    }, []);

    useEffect(() => {
        if (id && tutorias.length) {
            const initialTutorial = tutorias.filter(
                (tutorial) => tutorial.id === parseInt(id)
            )[0];

            console.log(initialTutorial);

            if (initialTutorial && Object.values(initialTutorial).length) {
                setTutorial(initialTutorial.tutorial_passos);
                setVideo(initialTutorial.tutorial_url);
                setImgVoucher(initialTutorial.foto_capa);
                setTutorialObservacao(initialTutorial.tutorial_observacao);
                setProdutoId(initialTutorial.id);
                setModalTutorial(true);
                setDados(initialTutorial);
            }
        }
    }, [id, tutorias]);

    return (
        <Container>
            <Modal
                component={ModalTutorial}
                active={modalTutorial}
                rest={configProduto}
                onClick={clickOutModal}
                maxWidthMobile={true}
            />

            <Modal
                onClick={clickOutModal}
                component={ModalVideo}
                active={modalVideo}
                rest={configVideo}
            />
        </Container>
    );
}

import { Header } from "./header";
import { Boxs } from "./Boxs";
import { Regras } from "./Regras";
import Modal from "../NovoModal";
import { TableDetalhes } from "./Table";
import { Container } from "./styles";
import { useDetalheCombo } from "../../Hook/useDetalheCombo";
import { FormEdit } from "./FormEdit";

export function DetalheCombo() {
    const { modal, setModal } = useDetalheCombo();

    function handleCloseModal() {
        setModal((prev) => !prev);
    }

    return (
        <Container>
            <Header />
            <Boxs />
            <Regras />
            <TableDetalhes />

            <Modal open={modal} handleClose={handleCloseModal}>
                {modal ? <FormEdit /> : <></>}
            </Modal>
        </Container>
    );
}

import { useEffect, useState } from "react";
import { ButtonSystem, Flex, Text } from "aplication-yetz";
import { useProduto } from "../../Hook/useProduto";
import { TextArea } from "../../components/TextArea";
import { DrafEditor } from "../../components/Editor/editorPassoAPasso";
import fecharImg from "../../assets/sistema/fechar-modal.svg";

import { Formik, Form, ErrorMessage, FormikProps, FormikHelpers } from "formik";

import { initialValuesTutorial, SchemaTutorial } from "./config";
import { FormTutorial, keyOf } from "./type";

import { ContainerModalTutorial } from "./styles";
import { regexTagStyles } from "../../config/regex";

interface ModalTutorialProps {
    rest: {
        onClose: () => void;
        id: number;
        tutorial: {
            tutorial_passos: string;
            tutorial_observacao: string;
            tutorial_url: string;
            external_link: string;
            external_link_label: string;
        };
    };
}

export function TutorialProduto(props: ModalTutorialProps) {
    const { storePassos } = useProduto();
    const [passos, setPassos] = useState<number[]>([]);
    const [passosValues, setPassosValues] = useState<object>({});
    const [observacao, setObservacao] = useState<boolean>(false);
    const [video, setVideo] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [intialValue, setIntialValue] = useState<FormTutorial>(
        initialValuesTutorial
    );

    const [removeItem, setRemoveItem] = useState<string>("");

    function handleObservacao() {
        setObservacao(!observacao);
    }

    function handleVideo() {
        setVideo(!video);
    }

    function resetInitialValue(values: FormTutorial) {
        setIntialValue({
            ...values,
            tutorial_passos: JSON.stringify(Object.values(passosValues)),
        });
    }

    async function handleSubmit(
        values: FormData,
        actions: FormikHelpers<FormTutorial>
    ) {
        setLoading(true);

        const response = await storePassos(values, props.rest.id);

        if (response.status === 200) {
            actions.resetForm();
            // actions.setValues(initialValuesTutorial);
            props.rest.onClose();
            setLoading(false);
            setPassosValues({});
            setPassos([]);
        }
    }

    useEffect(() => {
        const tutorial = props.rest.tutorial;
        if (tutorial) {
            const arrPassos = JSON.parse(
                tutorial.tutorial_passos === "null"
                    ? "[]"
                    : tutorial.tutorial_passos
            );

            const arrPassosValues = Object.values(arrPassos).reduce(
                (acc: any, item, key) => {
                    acc[`passo-${key}`] = item;
                    return acc;
                },
                {}
            ) as object;

            setPassosValues(arrPassosValues);

            setPassos(Object.keys(arrPassos).map((item) => parseInt(item)));
            setIntialValue(tutorial);
        }
    }, [props.rest.tutorial]);

    useEffect(() => {
        if (removeItem !== "" && removeItem) {
            const arrPassos = Object.values(passosValues);

            const newArrPassos = arrPassos.filter((item: string) => {
                return item !== removeItem;
            });

            const newPassosValues: any = { ...passosValues };

            const teste = Object.values(newPassosValues).reduce(
                (acc: any, item, key) => {
                    if (item !== removeItem) {
                        acc[`passo-${key}`] = item;
                    }

                    return acc;
                },
                {}
            ) as object;

            setPassosValues(teste);

            setPassos(Object.keys(newArrPassos).map((item) => parseInt(item)));

            setIntialValue({
                ...intialValue,
                tutorial_passos: JSON.stringify(newArrPassos),
            });

            setRemoveItem("");
        }
    }, [intialValue, removeItem, passosValues]);

    return (
        <ContainerModalTutorial>
            <Flex alignItems="center" justifyContent="space-between" mb="32px">
                <Text as="p" fontWeight="bold">
                    Tutorial do Produto
                </Text>
                <button
                    type="button"
                    onClick={() => {
                        if (props.rest.onClose) {
                            props.rest.onClose();
                        }
                    }}
                >
                    <img src={fecharImg} alt="" />
                </button>
            </Flex>

            <Formik
                initialValues={intialValue}
                enableReinitialize
                validationSchema={SchemaTutorial}
                onSubmit={(values: FormTutorial, actions) => {
                    const arrPassos = Object.values(passosValues);

                    if (!arrPassos.length) {
                        console.log(values.tutorial_passos);
                        actions.setFieldError(
                            "tutorial_passos",
                            "Campo obrigatório"
                        );
                        return;
                    }

                    const dados = {
                        tutorial_observacao: values.tutorial_observacao
                            ? values.tutorial_observacao
                            : "",
                        tutorial_url: values.tutorial_url
                            ? values.tutorial_url
                            : "",
                        tutorial_passos: [...arrPassos],
                        external_link: values.external_link,
                        external_link_label: values.external_link_label,
                    };

                    const formData = new FormData();
                    //add passos no formData
                    const keysPassos = Object.keys(
                        dados
                    ) as keyOf<FormTutorial>[];
                    keysPassos.forEach((key) => {
                        if (key !== "tutorial_passos") {
                            formData.append(key, dados[key]);
                        }
                    });
                    formData.append(
                        "tutorial_passos",
                        JSON.stringify(dados.tutorial_passos)
                    );
                    handleSubmit(formData, actions);
                }}
            >
                {(props: FormikProps<FormTutorial>) => {
                    const { setFieldValue, values } = props;

                    return (
                        <Form>
                            <button
                                type="button"
                                className="passos"
                                onClick={() => {
                                    setPassos([...passos, passos.length + 1]);
                                    resetInitialValue(values);
                                }}
                            >
                                Adicionar passo
                            </button>

                            <>
                                {passos.map((item, key, arrar) => {
                                    const arrPassos = JSON.parse(
                                        values.tutorial_passos === "null"
                                            ? "[]"
                                            : values.tutorial_passos
                                    );

                                    return (
                                        <DrafEditor
                                            key={key}
                                            label={`passo ${key + 1}`}
                                            values={arrPassos[key]}
                                            onChangeButton={() => {
                                                const passosArr =
                                                    arrPassos.filter(
                                                        (passo: string) =>
                                                            passo !==
                                                            arrPassos[key]
                                                    );

                                                setRemoveItem(arrPassos[key]);

                                                setPassos(
                                                    Object.keys(passosArr).map(
                                                        (passo) => {
                                                            return parseInt(
                                                                passo
                                                            );
                                                        }
                                                    )
                                                );
                                            }}
                                            onChange={(value) => {
                                                setPassosValues({
                                                    ...passosValues,
                                                    [`passo-${item}`]:
                                                        value.replace(
                                                            regexTagStyles,
                                                            ""
                                                        ),
                                                });
                                            }}
                                        />
                                    );
                                })}
                                <div className="container-erro-passo-a-passo">
                                    <ErrorMessage
                                        component="small"
                                        name="tutorial_passos"
                                    />
                                </div>
                            </>

                            <TextArea
                                title="ADICIONAR OBSERVAÇÕES"
                                has={true}
                                active={observacao}
                                onChangeActive={handleObservacao}
                                rows={5}
                                value={values.tutorial_observacao}
                                onChange={(value) => {
                                    setFieldValue("tutorial_observacao", value);
                                }}
                                name="tutorial_observacao"
                            />
                            <TextArea
                                title="ADICIONAR VÍDEO"
                                has={true}
                                rows={1}
                                active={video}
                                onChangeActive={handleVideo}
                                value={values.tutorial_url}
                                name="tutorial_url"
                                onChange={(value) =>
                                    setFieldValue("tutorial_url", value)
                                }
                            />

                            <TextArea
                                title="LINK APP / WEBSITE"
                                has={true}
                                rows={1}
                                value={values.external_link}
                                name="external_link"
                                onChange={(value) =>
                                    setFieldValue("external_link", value)
                                }
                            />

                            <TextArea
                                title="LABEL LINK APP / WEBSITE"
                                has={true}
                                rows={1}
                                value={values.external_link_label}
                                name="external_link_label"
                                onChange={(value) =>
                                    setFieldValue("external_link_label", value)
                                }
                            />

                            <ButtonSystem
                                loading={loading}
                                widthSpnier={22}
                                heightSpiner={22}
                            >
                                Salvar edição
                            </ButtonSystem>
                        </Form>
                    );
                }}
            </Formik>
        </ContainerModalTutorial>
    );
}

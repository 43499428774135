import styled from "styled-components";

export const Container = styled.div`
    width: 280px;
    display: flex;
    justify-content: space-between;
    button {
        &.active {
            span {
                &:before {
                    background: ${({ theme }) => theme.colors.primary};
                }
            }
        }
        &:first-child {
            span {
                &:before {
                    width: 27.01px;
                    height: 27.01px;
                }
            }
        }
        &:last-child {
            span {
                &:before {
                    width: 28.01px;
                    height: 31.01px;
                }
            }
        }
    }
`;

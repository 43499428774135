import { Box, TextImg } from "aplication-yetz";
import { formatCurrentMoney } from "../../util/helper";
import { CardsStyles } from "./styles";
import { BoxDetalheProduto } from "../BoxDetalheProduto";
import { BoxCodigo } from "./Box";

import pcImg from "../../assets/sistema/pc.svg";
import img01 from "../../assets/sistema/money.svg";

interface CardProps {
    password: string | null;
    valor: string | null;
    foto_capa: string | null;
    selo: string | null;
    codes?: string[];
}

const selos = new Map([
    ["1", "Válido para utilização em loja virtual"],
    ["2", "Válido para utilização exclusiva em loja física"],
    ["3", "Válido exclusivo loja física e virtual"],
    ["4", "Válido exclusivo no app"],
    ["5", "Válido exclusivo no app e loja virtual"],
    ["6", "Exclusivo por telefone"],
]);

export function Cards(props: CardProps) {
    const { valor, foto_capa, selo, codes = [] } = props;
    const width = window.screen.width;

    const config = {
        img: img01,
        subTitulo: valor ? formatCurrentMoney(parseFloat(valor)) : "",
        titulo: "Valor",
    };

    return (
        <CardsStyles>
            <Box>
                <img src={foto_capa ? foto_capa : ""} alt="" className="card" />
            </Box>

            {width < 480 ? (
                <div className="container-selo">
                    <TextImg img={pcImg} className="text-img">
                        {selo && selos.get(selo.toString())}
                    </TextImg>
                </div>
            ) : (
                <></>
            )}

            <div className="box-valor">
                <BoxDetalheProduto {...config} />
            </div>

            <div className="boxs-codigo">
                {codes.length > 0 &&
                    codes.map((item, key) => (
                        <BoxCodigo code={item} key={key} index={key + 1} />
                    ))}
            </div>
        </CardsStyles>
    );
}

import { Container } from "./styles";
import { Header } from "./Header";
import { Codigo } from "./Codigo";
import { Senha } from "./Senha";
import { Validade } from "./Validade";
import { Resgatar } from "./Resgatar";
import { Compartilhar } from "./Compartilhar";
import { Item } from "../../../pages/PageValePresente";
import { When } from "../../When";

export function Cards(props: Item) {
    const { codes, password, nome, valor, external_link, external_link_label } =
        props;

    return (
        <Container>
            <Header
                nome={nome || ""}
                value={valor || ""}
                foto={props.foto_capa || ""}
            />

            <When expr={Boolean(external_link && external_link_label)}>
                <Resgatar link={external_link} label={external_link_label} />
            </When>

            <When expr={!Boolean(external_link && external_link_label)}>
                <div className="cards">
                    {codes ? <Codigo code={codes[0]} /> : <></>}
                    {password ? <Senha password={password} /> : <></>}
                </div>
            </When>

            <Validade />

            <Compartilhar {...props} />
        </Container>
    );
}

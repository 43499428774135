import styled from "styled-components";
import { css } from "styled-components";

interface BoxCodigoProps {
    password?: string;
}

const password = css`
    display: flex;
    width: 100%;
    .codigo-vale {
        border-bottom: none !important;
        margin: 0px !important;
        padding: 0 !important;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:last-child {
        }
    }

    @media (max-width: 480px) {
        padding: 20px 10px;
        span,
        small {
            text-align: center;
        }
    }
`;

export const Container = styled.div<BoxCodigoProps>`
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 16px;
    padding-bottom: 16px;
    &:last-child {
        border-bottom: none;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    ${(props) => (props.password ? password : "")}
`;

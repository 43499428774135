import { Column } from "react-table";
import { Data } from "./types";

export const column: Column<Data>[] = [
    {
        Header: "IMAGEM",
        accessor: "img",
    },

    {
        Header: "COD. PROD",
        accessor: "cod_prod",
    },

    {
        Header: "PRODUTO",
        accessor: "produto",
    },

    {
        Header: "QUANTIDADE",
        accessor: "quantidade",
    },
];

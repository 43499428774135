import { formatCurrentMoney } from "../../../../util/helper";
import { Container } from "./styles";

interface HeaderProps {
    nome: string;
    value: string;
    foto: string;
}

export function Header(props: HeaderProps) {
    return (
        <Container>
            <div className="container-img">
                <img src={props.foto} alt="" />
            </div>

            <div className="container-header">
                <h3>{props.nome}</h3>
                <p>
                    Valor:{" "}
                    {formatCurrentMoney(
                        parseFloat(props.value ? props.value + "" : "")
                    )}
                </p>
            </div>
        </Container>
    );
}

import styled from "styled-components";

export const Container = styled.div`
    margin-bottom: 16px;
    button {
        width: 100%;
        height: 41px;
        padding: 12px 25px 12px 25px;
        border-radius: 20px;
        background: #f17e21;

        font-family: "Lato";
        font-size: 14px;
        font-weight: 700;
        line-height: 16.8px;
        text-align: center;
        color: #ffffff;
    }
`;

import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../services";
import { Categorias } from "./useCombo";
import { Categoria, Variacao } from "./useProduto";
import { Option } from "../types";
import { FormCombo } from "../components/DetalheCombo/FormEdit/types";
import { toast } from "react-toastify";

interface DetalheComboData {
    detalheCombo: DetalheCombo;
    loading: boolean;

    onLoading: boolean;
    categorias: Option[];
    produtos: Variacao[];
    produtosIdCombo: number[];

    modal: boolean;
    setModal: (value: React.SetStateAction<boolean>) => void;
    onLoadModal(): Promise<void>;
    getInitialValues(): FormCombo;
    setProdutosIdCombo: (value: React.SetStateAction<number[]>) => void;
    filterProdutos(value: string): Variacao[];
    updateCombo(values: FormCombo): Promise<void>;
}

interface ProdutosVariacao {
    alerta_estoque: number;
    created_at: string;
    foto_capa: string;
    id: number;
    nome: string;
    porcentagem: null;
    produto_id: number;
    status: number;
    status_alerta_estoque: number;
    tipo: string;
    updated_at: string;
    valor_reais: string;
    valor_yetz: string;
}

interface ProdutosVariacoes {
    combo_id: number;
    created_at: string;
    updated_at: string;
    id: number;
    produto_variacao_id: number;
    produtos_variacao: ProdutosVariacao;
}

interface DetalheCombo {
    created_at: string;
    descricao: string;
    detalhes: string;
    foto_capa: string;
    id: number;
    informacoes_importantes: string;
    informacoes_temporarias: string;
    nome: string;
    produtos_variacoes: ProdutosVariacoes[];
    categorias: Categorias[];
    status: number;
    template_voucher_id: number;
    tipo: string;
    updated_at: string;
}

interface ComboProvider {
    children: React.ReactNode;
}

export interface HistoryProps {
    id?: number;
}

const Context = createContext<DetalheComboData>({} as DetalheComboData);

export function UseDetalheComboProvider(props: ComboProvider) {
    const { children } = props;
    const history = useHistory<{ id: number }>();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [onLoading, setOnLoading] = useState(false);
    const [categorias, setCategorias] = useState<Option[]>([]);
    const [produtos, setProdutos] = useState<Variacao[]>([]);
    const [produtosIdCombo, setProdutosIdCombo] = useState<number[]>([]);

    const [detalheCombo, setDetalheCombo] = useState<DetalheCombo>(
        {} as DetalheCombo
    );
    const id =
        history.location.state?.id || window.location.pathname.split("/").pop();

    async function updateCombo(values: FormCombo) {
        const keys = Object.keys(values) as Array<keyof FormCombo>;
        const formData = new FormData();
        const removeKeys = ["produto_variacao", "categorias"];

        keys.forEach((key) => {
            if (!removeKeys.includes(key))
                formData.append(key, values[key] as string);
        });

        for (let i = 0; i < values?.categorias?.length; i++) {
            formData.append("categorias[]", values.categorias[i].toString());
        }

        try {
            await api.post(`/combo/${id}`, formData);
            await getDetalheCombo();
            toast.success("Combo atualizado com sucesso");
            setModal(false);
        } catch (error) {
            console.log(error);
            throw new Error("Erro ao criar combo");
        }
    }

    async function getCategoria() {
        try {
            const { data } = await api.get("/categoria");
            return data;
        } catch (error) {
            console.log(error);
            return [] as Categorias[];
        }
    }

    async function getProdutos() {
        try {
            const { data } = await api.get<Variacao[]>("/produto/variacao");
            return data as Variacao[];
        } catch (error) {
            console.log(error);
            return [] as Variacao[];
        }
    }

    async function onLoadModal() {
        try {
            setOnLoading(true);
            const [produtos, categorias] = await Promise.all([
                getProdutos(),
                getCategoria(),
            ]);
            setCategorias(createOptions(categorias));
            setProdutos(produtos);
            setOnLoading(false);
        } catch (error) {
            setOnLoading(false);
            console.log(error);
            throw new Error("Erro ao carregar dados");
        }
    }

    async function getDetalheCombo() {
        try {
            setLoading(true);
            const { data } = await api.get(`/combo/${id}`);
            setLoading(false);
            setDetalheCombo(data);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    function createOptions(data: any[]) {
        return data.map((item: Categoria) => ({
            value: item.id,
            label: item.nome,
        }));
    }

    function filterProdutos(value: string) {
        if (value === "") return produtos;

        return produtos.filter((produto) =>
            JSON.stringify(produto, ["nome"])
                .toLowerCase()
                .includes(value.toLowerCase())
        );
    }

    function getInitialValues(): FormCombo {
        return {
            nome: detalheCombo.nome,
            descricao: detalheCombo.descricao,
            informacoes_importantes: detalheCombo.informacoes_importantes,
            informacoes_temporarias: detalheCombo.informacoes_temporarias,
            status: detalheCombo.status,
            categorias: detalheCombo.categorias.map(
                (item) => item.categoria.id
            ),
            produto_variacao: detalheCombo.produtos_variacoes.map(
                (item) => item.produtos_variacao.id
            ),
            detalhes: detalheCombo.detalhes,
            foto_capa: detalheCombo.foto_capa,
            template_voucher_id: detalheCombo.template_voucher_id.toString(),
            tipo: detalheCombo.tipo,
        };
    }

    useEffect(() => {
        getDetalheCombo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Context.Provider
            value={{
                detalheCombo,
                loading,
                modal,
                setModal,
                onLoading,
                categorias,
                produtos,
                onLoadModal,
                getInitialValues,
                produtosIdCombo,
                setProdutosIdCombo,
                filterProdutos,
                updateCombo,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useDetalheCombo() {
    const context = useContext(Context);

    return context;
}

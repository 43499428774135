import styled from "styled-components";
import { Container as Codigo } from "./Codigo/styles";
import { Container as Senha } from "./Senha/styles";
import { Container as Header } from "./Header/styles";
import { Container as Validade } from "./Validade/styles";
import { Container as Compartilhar } from "./Compartilhar/styles";

export const Container = styled.div`
    padding: 25px 12.25px 25px 12.25px;
    background: #f7f7f7;
    /* background: red; */
    border-radius: 10px;
    width: 100%;
    margin-bottom: 10px;
    &.disabled {
        opacity: 1;
        cursor: not-allowed;
        pointer-events: all;
        ${Header} {
            .container-img {
                img {
                    filter: grayscale(100%);
                }
            }

            .container-header {
                h3 {
                    color: #909191;
                }

                p {
                    color: #909191;
                }
            }
        }

        ${Codigo} {
            .container-text {
                h3 {
                    color: #909191;
                }

                p {
                    color: #909191;
                }
            }

            button {
                pointer-events: none;
                img {
                    filter: grayscale(100%);
                }
            }
        }

        ${Senha} {
            .container-text {
                h3 {
                    color: #909191;
                }

                p {
                    color: #909191;
                }
            }

            button {
                pointer-events: none;
                img {
                    filter: grayscale(100%);
                }
            }
        }

        ${Compartilhar} {
            .container-btn {
                .container-img {
                    img {
                        filter: grayscale(100%);
                    }
                }
                button {
                    pointer-events: none;
                    color: #909191;
                }
            }
        }

        ${Validade} {
            p {
                color: #d50632;
            }
        }
    }

    .cards {
        margin-bottom: 16px;
    }
    ${Codigo} {
        &:last-child {
            margin-bottom: 0px;
        }
    }
`;

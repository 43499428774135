import { useEffect, useState, useCallback, useMemo } from "react";
import { Box, Flex, Text, TextImg, ButtonSystem } from "aplication-yetz";
import {
    Formik,
    Field,
    Form,
    ErrorMessage,
    FormikProps,
    FormikHelpers,
} from "formik";
import { useProduto } from "../../Hook/useProduto";
import { Input, InputMask } from "../../components/Input";
import { Toggle } from "../../components/Toggle";

import { addSchema, initialValues } from "./config";
import { FormProduto, keyOfProduto, Categoria } from "./types";
import { Select, MultipleSelect } from "../../components/Select";

import { Option } from "../../types";

import imgCamera from "../../assets/sistema/camera.svg";
import fecharModalImg from "../../assets/sistema/fechar-modal.svg";

import { ContainerModal, ContainerImg } from "./styles";
import { toast } from "react-toastify";
import { Galery } from "./galery";
import { AlertDialog } from "../AlertDialog";
import { DrafEditor } from "../Editor";
import { TextAreaStyles } from "../Input/styles";

interface ModalProdutoProps {
    rest: {
        title: string;
        description: string;
        onClose: (active: boolean) => void;
        produto?: FormProduto;
        id: number;
        modalActive: boolean;
    };
}

export function ModalProduto(props: ModalProdutoProps) {
    const { rest } = props;
    const { produto, modalActive } = props.rest;

    const {
        store,
        update,
        getCategoria,
        alertDialog,
        setAlertDialog,
        fileImgProduto,
        UpdateItemGaleria,
        RemoveItemGaleria,
        galeria,
    } = useProduto();
    const [categorias, setCategorias] = useState<Categoria[]>([]);
    const [value, setValue] = useState<FormProduto>(initialValues);
    const [loading, setLoading] = useState<boolean>(false);
    const [informacoesImportantes, setInformacoesImportantes] = useState("");
    const [informacoesTemporarias, setInformacoesTemporarias] = useState("");
    const [detalhes, setDetalhes] = useState("");
    const [descricao, setDescricao] = useState("");

    const hasProduto = produto ? true : false;
    let carregou = false;

    const optionsCategoria: Option[] = categorias.map((item) => {
        return {
            value: item.id,
            label: item.nome,
        };
    });

    const tipo: Option[] = useMemo(() => {
        return [
            {
                value: 1,
                label: "CARTAO_FISICO",
            },
            {
                value: 2,
                label: "VOUCHER_DIGITAL",
            },
            {
                value: 2,
                label: "VOUCHER_YETZ",
            },
        ];
    }, []);

    const template: Option[] = useMemo(() => {
        return [
            {
                label: "CODIGO",
                value: 1,
            },
            {
                label: "CODIGOSENHA",
                value: 2,
            },
            {
                label: "LINK",
                value: 3,
            },
        ];
    }, []);

    const templateVoucher: Option[] = useMemo(() => {
        return [
            {
                value: 1,
                label: "Padrão",
            },
            {
                value: 2,
                label: "Par de Ingresso Personnalite",
            },
            {
                value: 3,
                label: "Combo de Ingresso",
            },
            {
                value: 4,
                label: "GrandCru",
            },
            {
                value: 5,
                label: "Personnalite Codigo / Senha",
            },
            {
                value: 6,
                label: "Uniclass",
            },
            {
                value: 7,
                label: "Personnalite Codigo / Senha - Não mostrar valor",
            },
            {
                value: 8,
                label: "Uniclass - Não mostrar valor",
            },
            {
                value: 9,
                label: "Cinemark Private",
            },
            {
                value: 10,
                label: "Grand Cru Private",
            },
            {
                value: 11,
                label: "Schweppes",
            },
            {
                value: 12,
                label: "Personnalite Link",
            },
            {
                value: 13,
                label: "Uniclass Link ",
            },
            {
                value: 14,
                label: "Person Link sem valor",
            },
            {
                value: 15,
                label: "Person sem valor com link copo stanley",
            },
            {
                value: 16,
                label: "MV - Grand Cru Itaú Shop",
            },
            {
                value: 17,
                label: "Stone",
            },
            {
                value: 18,
                label: "Combo Padrão",
            },
        ];
    }, []);

    const selo: Option[] = useMemo(() => {
        return [
            {
                value: 1,
                label: "Válido para utilização exclusiva no site",
            },
            {
                value: 2,
                label: "Válido para utilização exclusiva na loja física",
            },
            {
                value: 3,
                label: "Válido para utilização exclusiva na loja física e no site",
            },
            {
                value: 4,
                label: "Válido para utilização exclusiva no APP",
            },
            {
                value: 5,
                label: "Válido para utilização exclusiva pelo telefone",
            },
            {
                value: 6,
                label: "Válido para utilização exclusiva no site e APP",
            },
        ];
    }, []);

    const hasTipo = useCallback(() => {
        if (hasProduto) {
            return tipo.filter((item) => item.label === produto?.tipo)[0];
        }
        return null;
    }, [tipo, produto, hasProduto]);

    const hasTemplate = useCallback(() => {
        if (hasProduto) {
            return template.filter(
                (item) => item.label === produto?.template
            )[0];
        }
        return null;
    }, [template, produto, hasProduto]);

    const hasTemplateVoucher = useCallback(() => {
        if (hasProduto) {
            return templateVoucher.filter(
                (item) =>
                    item.value ===
                    parseInt(
                        produto?.template_voucher_id
                            ? produto.template_voucher_id
                            : "0"
                    )
            )[0];
        }
        return null;
    }, [templateVoucher, produto, hasProduto]);

    const hasSelo = useCallback(() => {
        if (hasProduto) {
            return selo.filter(
                (item) =>
                    item.value === parseInt(produto?.selo ? produto.selo : "0")
            )[0];
        }
        return null;
    }, [selo, produto, hasProduto]);

    const hasCategoria = useCallback(() => {
        if (hasProduto) {
            return optionsCategoria.reduce((acc, item) => {
                if (produto?.categorias.includes(item.value)) {
                    acc.push(item);
                }
                // eslint-disable-next-line
                carregou = true;
                return acc;
            }, [] as Option[]);
        }
        return null;
    }, [produto, hasProduto, optionsCategoria]);
    hasProduto && hasCategoria();

    async function handleSubmit(
        values: FormData,
        actions: FormikHelpers<FormProduto>
    ) {
        setLoading(true);

        const response = hasProduto
            ? await update(values, rest.id)
            : await store(values);

        if (response.status === 200) {
            actions.resetForm();
            toast.success("sucesso!");
            rest.onClose(false);
        }

        setLoading(false);
    }

    useEffect(() => {
        async function fetchData() {
            const dados = await getCategoria();
            setCategorias(dados);
        }
        if (modalActive) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [modalActive]);

    useEffect(() => {
        if (rest.produto) {
            setValue(rest.produto);
            setInformacoesImportantes(rest.produto.informacoes_importantes);
            setInformacoesTemporarias(rest.produto.informacoes_temporarias);
            setDetalhes(rest.produto.detalhes);
            setDescricao(rest.produto.descricao);
        }
    }, [rest.produto]);

    console.log(hasCategoria());

    return (
        <ContainerModal>
            <Formik
                initialValues={value}
                enableReinitialize
                validationSchema={addSchema}
                onSubmit={(values: FormProduto, actions) => {
                    values = {
                        ...values,
                        informacoes_importantes: informacoesImportantes,
                        informacoes_temporarias: informacoesTemporarias,
                        detalhes: detalhes,
                        descricao: descricao,
                    };

                    if (informacoesImportantes.length === 0) {
                        actions.setFieldError(
                            "informacoes_importantes",
                            "Campo 'Informações importantes' é obrigatório"
                        );
                        toast.error(
                            "Campo 'Informações importantes' é obrigatório"
                        );
                        return;
                    }

                    if (informacoesTemporarias.length === 0) {
                        actions.setFieldError(
                            "informacoes_temporarias",
                            "Campo 'Informações temporárias' é obrigatório"
                        );
                        toast.error(
                            "Campo 'Informações temporárias' é obrigatório"
                        );
                        return;
                    }

                    if (descricao.length === 0) {
                        actions.setFieldError(
                            "descricao",
                            "Campo 'Descrição' é obrigatório"
                        );
                        toast.error("Campo 'Descrição' é obrigatório");
                        return;
                    }

                    const keys = Object.keys(
                        values
                    ) as keyOfProduto<FormProduto>[];
                    const formData = new FormData();

                    keys.forEach((key) => {
                        if (
                            key !== "categorias" &&
                            key !== "foto_capa" &&
                            key !== "status" &&
                            key !== "template_validade_fixa"
                        ) {
                            formData.append(key, values[key]);
                        }
                    });

                    if (typeof values.foto_capa !== "string") {
                        formData.append("foto_capa", values.foto_capa);
                    }

                    for (let i = 0; i < galeria.length; i++) {
                        formData.append("gallery[]", galeria[i].id);
                    }

                    if (values.status) {
                        formData.append("status", "1");
                    } else {
                        formData.append("status", "0");
                    }

                    if (values.template_validade_fixa) {
                        formData.append("template_validade_fixa", "1");
                    } else {
                        formData.append("template_validade_fixa", "0");
                    }

                    formData.append(
                        "categorias",
                        JSON.stringify(values.categorias)
                    );

                    // keys.forEach((key) => {
                    //     console.log(`${key} - ${formData.get(key)}`);
                    // });

                    handleSubmit(formData, actions);
                }}
            >
                {(props: FormikProps<FormProduto>) => {
                    const { setFieldValue, values } = props;
                    const { status, template_validade_fixa } = values;

                    const url =
                        typeof values.foto_capa === "string" &&
                        values.foto_capa !== ""
                            ? values.foto_capa
                            : values.foto_capa
                            ? URL.createObjectURL(values.foto_capa)
                            : null;

                    return (
                        <Form>
                            <ContainerImg
                                alignItems="start"
                                justifyContent="space-between"
                            >
                                <Box position="relative">
                                    {url ? (
                                        <>
                                            <img
                                                className="file"
                                                src={url}
                                                alt=""
                                            />

                                            <button
                                                className="remover-img"
                                                type="button"
                                                onClick={() =>
                                                    setFieldValue(
                                                        "foto_capa",
                                                        ""
                                                    )
                                                }
                                            >
                                                <img
                                                    src={fecharModalImg}
                                                    alt=""
                                                />
                                            </button>
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    <label htmlFor="file">
                                        <TextImg
                                            mt="39px"
                                            color="primary"
                                            className="text-img"
                                            img={imgCamera}
                                        >
                                            Atualizar imagem
                                        </TextImg>
                                    </label>
                                    <ErrorMessage
                                        component="small"
                                        name="foto_capa"
                                    />

                                    <Field component={Galery} name="gallery" />
                                    <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        accept="image/png, image/gif, image/jpeg"
                                        onChange={(e) => {
                                            setFieldValue(
                                                "foto_capa",
                                                e.target.files?.length
                                                    ? e.target.files[0]
                                                    : null
                                            );
                                        }}
                                    />
                                </Box>

                                <Box>
                                    <button
                                        onClick={() => rest.onClose(false)}
                                        type="button"
                                    >
                                        <img src={fecharModalImg} alt="" />
                                    </button>
                                </Box>
                            </ContainerImg>

                            <Box>
                                <div className="container-form">
                                    <Field
                                        component={Input}
                                        name="nome"
                                        label="NOME"
                                    />
                                    <ErrorMessage
                                        component="small"
                                        name="nome"
                                    />
                                </div>

                                <div className="container-form">
                                    {carregou && (
                                        <MultipleSelect
                                            value={hasCategoria()}
                                            placeholder="CATEGORIA"
                                            onChange={(item) => {
                                                setFieldValue("categorias", [
                                                    ...item.map(
                                                        (item) => item.value
                                                    ),
                                                ]);
                                            }}
                                            options={optionsCategoria}
                                        />
                                    )}
                                    {!carregou && !hasProduto && (
                                        <MultipleSelect
                                            placeholder="CATEGORIA"
                                            onChange={(item) => {
                                                setFieldValue("categorias", [
                                                    ...item.map(
                                                        (item) => item.value
                                                    ),
                                                ]);
                                            }}
                                            options={optionsCategoria}
                                        />
                                    )}

                                    <ErrorMessage
                                        component="small"
                                        name="categorias"
                                    />
                                </div>

                                <div className="container-form">
                                    <Select
                                        initalValue={hasTipo()}
                                        placeholder="TIPO"
                                        onChange={(item) =>
                                            setFieldValue("tipo", item.label)
                                        }
                                        options={tipo}
                                    />
                                    <ErrorMessage
                                        component="small"
                                        name="tipo"
                                    />
                                </div>

                                <div className="container-form">
                                    <Field
                                        component={Input}
                                        maxlength={3}
                                        parse="number"
                                        name="data_outlet"
                                        label="QUANDO ENTRA EM OUTLET"
                                    />
                                    <ErrorMessage
                                        component="small"
                                        name="data_outlet"
                                    />
                                </div>

                                <div className="container-form">
                                    <Field
                                        component={Input}
                                        parse="number"
                                        maxlength={3}
                                        name="validade"
                                        label="VALIDADE"
                                    />
                                    <ErrorMessage
                                        component="small"
                                        name="validade"
                                    />
                                </div>

                                <div className="container-form">
                                    <Field
                                        component={InputMask}
                                        mask="99.99"
                                        parse="float"
                                        name="desconto_outlet"
                                        label="DESCONTO EM OUTLET"
                                    />
                                    <ErrorMessage
                                        component="small"
                                        name="desconto_outlet"
                                    />
                                </div>

                                <div className="container-form">
                                    <Field
                                        component={Input}
                                        maxlength={2}
                                        parse="number"
                                        name="alerta_estoque"
                                        label="ALERTA ESTOQUE"
                                    />
                                    <ErrorMessage
                                        component="small"
                                        name="alerta_estoque"
                                    />
                                </div>

                                <div className="container-form">
                                    <Select
                                        initalValue={hasSelo()}
                                        placeholder="SELO"
                                        onChange={(item) =>
                                            setFieldValue("selo", item.value)
                                        }
                                        options={selo}
                                    />
                                    <ErrorMessage
                                        component="small"
                                        name="selo"
                                    />
                                </div>

                                <div className="container-form status">
                                    <Flex
                                        justifyContent="space-between"
                                        width="100%"
                                    >
                                        <Text color="gray300">STATUS</Text>
                                        <Toggle
                                            titulo="Ativo"
                                            active={status ? true : false}
                                            onClick={() =>
                                                setFieldValue(
                                                    "status",
                                                    !status ? 1 : 0
                                                )
                                            }
                                        />
                                    </Flex>
                                </div>

                                {/* <div className="container-form status">
                                    <Flex
                                        justifyContent="space-between"
                                        width="100%"
                                    >
                                        <Text color="gray300">CUPOM</Text>
                                        <Toggle
                                            titulo="Ativo"
                                            active={cupom ? true : false}
                                            onClick={() =>
                                                setFieldValue(
                                                    "cupom",
                                                    !cupom ? 1 : 0
                                                )
                                            }
                                        />
                                    </Flex>
                                </div> */}

                                <div className="container-form">
                                    <Field
                                        component={Input}
                                        name="url_tutorial"
                                        label="LINK TUTORIAL"
                                    />
                                    <ErrorMessage
                                        component="small"
                                        name="url_tutorial"
                                    />
                                </div>

                                <div className="container-form">
                                    <Select
                                        initalValue={hasTemplate()}
                                        placeholder="TEMPLATE"
                                        onChange={(item) =>
                                            setFieldValue(
                                                "template",
                                                item.label
                                            )
                                        }
                                        options={template}
                                    />
                                    <ErrorMessage
                                        component="small"
                                        name="template"
                                    />
                                </div>

                                <div className="container-form">
                                    <Select
                                        initalValue={hasTemplateVoucher()}
                                        placeholder="TEMPLATE TELA DO VOUCHER"
                                        onChange={(item) =>
                                            setFieldValue(
                                                "template_voucher_id",
                                                item.value
                                            )
                                        }
                                        options={templateVoucher}
                                    />
                                    <ErrorMessage
                                        component="small"
                                        name="template_voucher_id"
                                    />
                                </div>

                                <div className="container-form status">
                                    <Flex
                                        justifyContent="space-between"
                                        width="100%"
                                    >
                                        <Text color="gray300">
                                            VALIDADE NO TEMPLATE FIXO ?
                                        </Text>
                                        <Toggle
                                            titulo={
                                                template_validade_fixa
                                                    ? "Sim"
                                                    : "Não"
                                            }
                                            active={
                                                template_validade_fixa
                                                    ? true
                                                    : false
                                            }
                                            onClick={() =>
                                                setFieldValue(
                                                    "template_validade_fixa",
                                                    !template_validade_fixa
                                                        ? 1
                                                        : 0
                                                )
                                            }
                                        />
                                    </Flex>
                                </div>

                                <div className="container-form">
                                    <Field
                                        component={Input}
                                        name="external_link_label"
                                        label="NOME BOTÃO VOUCHER"
                                    />
                                    <ErrorMessage
                                        component="small"
                                        name="external_link_label"
                                    />
                                </div>
                                <div className="container-form">
                                    <Field
                                        component={Input}
                                        name="external_link"
                                        label="LINK BOTÃO VOUCHER"
                                    />
                                    <ErrorMessage
                                        component="small"
                                        name="external_link"
                                    />
                                </div>
                            </Box>

                            <TextAreaStyles>
                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Text as="p">Descrição</Text>
                                </Flex>
                            </TextAreaStyles>
                            <DrafEditor
                                values={values.descricao}
                                onChange={(value) => {
                                    setDescricao(value);
                                }}
                            />
                            <ErrorMessage component="small" name="descricao" />

                            <TextAreaStyles>
                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Text as="p">Informações importantes</Text>
                                </Flex>
                            </TextAreaStyles>

                            <DrafEditor
                                values={values.informacoes_importantes}
                                onChange={(value) => {
                                    setInformacoesImportantes(value);
                                }}
                            />

                            <ErrorMessage
                                component="small"
                                name="informacoes_importantes"
                            />

                            <TextAreaStyles>
                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Text as="p">Informações Temporárias</Text>
                                </Flex>
                            </TextAreaStyles>

                            <DrafEditor
                                values={values.informacoes_temporarias}
                                onChange={(value) => {
                                    setInformacoesTemporarias(value);
                                }}
                            />
                            <ErrorMessage
                                component="small"
                                name="informacoes_temporarias"
                            />

                            <DrafEditor
                                values={values.detalhes}
                                onChange={(value) => {
                                    setDetalhes(value);
                                }}
                            />
                            <ErrorMessage component="small" name="detalhes" />

                            <ButtonSystem
                                width={246}
                                height={56}
                                heightSpiner={30}
                                widthSpnier={30}
                                loading={loading}
                                type="submit"
                            >
                                SALVAR ALTERAÇÕES
                            </ButtonSystem>
                        </Form>
                    );
                }}
            </Formik>

            <AlertDialog
                open={alertDialog}
                handleAccpet={() => {
                    if (fileImgProduto) {
                        UpdateItemGaleria();
                    } else {
                        RemoveItemGaleria();
                    }
                    setAlertDialog(false);
                }}
                handleClose={() => setAlertDialog(false)}
            />
        </ContainerModal>
    );
}

import styled from "styled-components";

export const Container = styled.div``;

export const RegrasStyles = styled.div`
    display: grid;
    grid-template-columns: 443px 347px 329px 1fr;
    justify-content: space-between;
    grid-gap: 100px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 49px;
    margin-bottom: 56px;
`;

import styled from "styled-components";

export const Container = styled.div`
    margin-bottom: 16px;
    p {
        font-family: "Lato";
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: 14.4px;
        text-align: center;
        color: #000000;
    }
`;

import React, { createContext, useContext, useState } from "react";
import { Item } from "../../../pages/PageValePresente";

interface UseCombosProps {
    children: React.ReactNode;
}

interface UseCombos {
    state: Item | null;
    setState: React.Dispatch<React.SetStateAction<Item | null>>;

    modal: boolean;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;

    tabs: Tabs[];
    setTabs: React.Dispatch<React.SetStateAction<Tabs[]>>;

    handleTabs(id: number): void;
}

export interface Tabs {
    id: number;
    title: string;
    active: boolean;
}

const itens: Tabs[] = [
    {
        id: 1,
        title: "combo",
        active: true,
    },
    {
        id: 2,
        title: "detalhe-combo",
        active: false,
    },
    {
        id: 3,
        title: "form-contato",
        active: false,
    },
];

const UseCombosContext = createContext<UseCombos>({} as UseCombos);

export function UseCombosProvider(props: UseCombosProps) {
    const { children } = props;
    const [state, setState] = useState<Item | null>(null);
    const [modal, setModal] = useState<boolean>(false);
    const [tabs, setTabs] = useState<Tabs[]>(itens);

    function handleTabs(id: number) {
        const newTabs = tabs.map((tab) => {
            if (tab.id === id) {
                return { ...tab, active: true };
            }
            return { ...tab, active: false };
        });

        setTabs(newTabs);
    }

    return (
        <UseCombosContext.Provider
            value={{
                state,
                setState,
                modal,
                setModal,
                tabs,
                setTabs,
                handleTabs,
            }}
        >
            {children}
        </UseCombosContext.Provider>
    );
}

export function useCombos() {
    const context = useContext(UseCombosContext);
    return context;
}

import { BoxCards } from "./styles";
import img01 from "../../../assets/sistema/logo-card-01.svg";
import img02 from "../../../assets/sistema/logo-card-02.svg";
import img03 from "../../../assets/sistema/logo-card-03.svg";
import img04 from "../../../assets/sistema/logo-card-04.svg";
import { BoxDetalheProdutoProps } from "./types";
import { BoxDetalheProduto } from "./detalhe";
import { useDetalheCombo } from "../../../Hook/useDetalheCombo";

export function Boxs() {
    const { detalheCombo } = useDetalheCombo();
    const arrayCard: BoxDetalheProdutoProps[] = [
        {
            img: img01,
            subTitulo: detalheCombo.id,
            titulo: "código",
        },
        {
            img: img02,
            subTitulo: "ver com lucas",
            titulo: "Categoria",
        },
        {
            img: img03,
            subTitulo: detalheCombo.tipo,
            titulo: "Voucher Digital",
        },
        {
            img: img04,
            subTitulo: " ver com lucas",
            titulo: "ESTIMATIVA DE ESTOQUE",
        },
    ];

    return (
        <BoxCards
            alignItems="flex-end"
            justifyContent="space-between"
            gridTemplateColumns="repeat(5, 1fr)"
            gridGap="22px"
            mb="74px"
        >
            <>
                <img
                    src={detalheCombo.foto_capa}
                    alt=""
                    className="img-destaque"
                />
                {arrayCard.map((item, key) => (
                    <BoxDetalheProduto {...item} key={key} />
                ))}
            </>
        </BoxCards>
    );
}

import { Container } from "./styles";

interface CardProps {
    card: string;
    index: number;
}

export function Card(props: CardProps) {
    const { card, index } = props;

    return (
        <Container>
            <div className="container-index">
                <p>0{index}</p>
            </div>

            <div
                className="container-detalhe"
                dangerouslySetInnerHTML={{
                    __html: card
                        .trim()
                        .replace(
                            /(?:|[^"]*[;\s])(background-color\s*:[^";]*)|(?:|[^"]*[;\s])(background\s*:[^";]*)/g,
                            ""
                        ),
                }}
            ></div>
        </Container>
    );
}

import { Column } from "react-table";
import { Data } from "./types";

export const column: Column<Data>[] = [
    {
        Header: "IMAGEM",
        accessor: "img",
    },

    {
        Header: "COD. COMBO",
        accessor: "cod_combo",
    },

    {
        Header: "TIPO DE COMBO",
        accessor: "tipo_de_combo",
    },

    {
        Header: "NOME DO COMBO",
        accessor: "nome_do_combo",
    },

    {
        Header: "ESTIMATIVA ESTOQUE",
        accessor: "estimativa_estoque",
    },
    {
        Header: "CATEGORIA",
        accessor: "categoria",
    },

    {
        Header: "STATUS",
        accessor: "status",
    },
];

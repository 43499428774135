import { Box, Filter } from "aplication-yetz";
import { FieldProps } from "formik";
import { ChangeEvent, useState } from "react";
import { Container } from "./styles";
import { useCombo } from "../../../../Hook/useCombo";
import { Card } from "../card";

type CombosProps = FieldProps & {};

export function Combos(props: CombosProps) {
    const { filterProdutos } = useCombo();
    const { form } = props;
    const { setFieldValue } = form;

    const [filter, setFilter] = useState("");
    const dadosFilter = filterProdutos(filter);

    return (
        <Container>
            <h4>Adicionar Produtos ao Combo:</h4>
            <Box my="16px" className="filter">
                <Filter
                    onClick={() => {}}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setFilter(e.target.value)
                    }
                />
            </Box>
            {dadosFilter.map((produto) => (
                <Card
                    {...produto}
                    onUpdate={(values) => {
                        setFieldValue("produto_variacao", values);
                    }}
                />
            ))}
        </Container>
    );
}

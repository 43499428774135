import { Catalogo } from "./Catalogo";
import { Header } from "./header";
import { Cards } from "./cards";
import { Combos } from "./Combos";
import { HeaderTabs } from "./HeaderTabs";
import { useEffect, useState } from "react";
import { usePlataforma } from "../../Hook/usePlataforma";
import { StyledPlataforma } from "./styles";
import { useTabs } from "./Hooks";

export function DetalhePlataforma() {
    const [hasEstoque, setHasEstoque] = useState<boolean>(false);
    const [status, setStatus] = useState<boolean>(false);
    const { user } = usePlataforma();
    const { tabs } = useTabs();

    const { catalogo_id = [] } = user;

    useEffect(() => {
        setHasEstoque(user["sem-estoque"]);
        setStatus(user.status ? true : false);
    }, [user]);

    return (
        <StyledPlataforma>
            <Header
                estoque={hasEstoque}
                status={status}
                catalogosIds={catalogo_id}
            />
            <Cards />

            <HeaderTabs />

            <div className="container-tabs">
                <div className={`tab ${tabs[0].active ? "active" : ""}`}>
                    <Catalogo hasEstoque={hasEstoque} status={status} />
                </div>
                <div className={`tab ${tabs[1].active ? "active" : ""}`}>
                    <Combos />
                </div>
            </div>
        </StyledPlataforma>
    );
}

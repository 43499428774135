import styled from "styled-components";

export const Container = styled.div`
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    position: relative;
    margin-bottom: 8px;

    .container-text {
        h3 {
            font-family: "Lato";
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            text-align: left;
            color: #5d5e5e;
        }

        p {
            //styleName: Yetz Subtitle 16;
            font-family: "Lato";
            font-size: 16px;
            font-weight: 700;
            line-height: 19.2px;
            text-align: left;
            color: #000000;
        }
    }

    button {
        position: absolute;
        top: 55%;
        transform: translateY(-50%);
        right: 25px;
        background-color: #fff;
        border: none;
        cursor: pointer;
        img {
            width: 20px;
            height: 20px;
        }
    }
`;

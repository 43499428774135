import { Grid } from "aplication-yetz";
import styled from "styled-components";

export const Container = styled.div``;

export const BoxCards = styled(Grid)`
    .img-destaque {
        width: 272px;
        height: 182px;
        border-radius: 5px;
        object-fit: contain;
    }
`;

import { Flex } from "aplication-yetz";
import { Toggle } from "../../../Toggle";
import { useState } from "react";
import { toast } from "react-toastify";
import { useCombo } from "../../../../Hook/useCombo";
import { FormCombo } from "../../FormAdd/types";
import { Loader } from "../../../Loader";

interface ToggleComboProps {
    status: number;
    id: number;
}
export function ToggleCombo(props: ToggleComboProps) {
    const { status, id } = props;
    const { updateCombo } = useCombo();
    const [loading, setLoading] = useState(false);

    async function updateStatus() {
        try {
            setLoading(true);
            await updateCombo(
                {
                    status: status === 1 ? 0 : 1,
                } as unknown as FormCombo,
                id
            );
            setLoading(false);
        } catch (error) {
            toast.error("Erro ao atualizar status");
            setLoading(false);
            console.log(error);
        }
    }

    return (
        <Flex width="131px" justifyContent="center">
            {loading ? (
                <Loader height={15} width={15} />
            ) : (
                <Toggle active={status === 1} onClick={updateStatus} />
            )}
        </Flex>
    );
}

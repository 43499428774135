import styled from "styled-components";

export const Container = styled.div`
    background: #f7f7f7;
    border-radius: 8px;
    padding: 43px 25px;
    margin-bottom: 32px;
    max-height: 52vh;
    height: auto;
    overflow-y: auto;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }
    .filter {
        margin-bottom: 50px;
        & > div {
            width: 100%;
        }
        button {
            display: none;
        }
        input {
            background: transparent;
        }
    }
    h3 {
        font-family: "Inter";
        font-size: 16px;
        font-weight: 700;
        line-height: 19.36px;
        text-align: left;
        font-weight: bold;
    }
`;

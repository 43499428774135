import React, { createContext, useContext, useState } from "react";
import { Item } from "../../../pages/PageValePresente";

interface UseTabsProps {
    children: React.ReactNode;
}

interface UseTabs {
    state: Item | null;
    setState: React.Dispatch<React.SetStateAction<Item | null>>;

    tabs: Tabs[];
    setTabs: React.Dispatch<React.SetStateAction<Tabs[]>>;

    handleTabs(id: number): void;
}

export interface Tabs {
    id: number;
    active: boolean;
}

const itens: Tabs[] = [
    {
        id: 1,
        active: true,
    },
    {
        id: 2,
        active: false,
    },
];

const UseTabsContext = createContext<UseTabs>({} as UseTabs);

export function UseTabsProvider(props: UseTabsProps) {
    const { children } = props;
    const [state, setState] = useState<Item | null>(null);
    const [tabs, setTabs] = useState<Tabs[]>(itens);

    function handleTabs(id: number) {
        const newTabs = tabs.map((tab) => {
            if (tab.id === id) {
                return { ...tab, active: true };
            }
            return { ...tab, active: false };
        });

        setTabs(newTabs);
    }

    return (
        <UseTabsContext.Provider
            value={{
                state,
                setState,
                tabs,
                setTabs,
                handleTabs,
            }}
        >
            {children}
        </UseTabsContext.Provider>
    );
}

export function useTabs() {
    const context = useContext(UseTabsContext);
    return context;
}

import { Cards } from "./Cards";
import { Container } from "./styles";
import { Loader } from "../Loader";
import { ResponseVoucher } from "../../pages/PageValePresente";
import { ModalVideo } from "../../components/PassoAPasso/video";
import { Modal } from "../../components/Modal";
import { useCombos } from "./Hook";
import { ComoUsar } from "./ComoUsar";

interface UserCombosProps {
    voucher?: ResponseVoucher;
    loading: boolean;
    setModalTutorial: (tutorial: boolean) => void;
    sandbox?: boolean;
}

export function UserCombos(props: UserCombosProps) {
    const { voucher, loading } = props;
    const { state, tabs, setModal, modal } = useCombos();

    const configVideo = {
        video: state?.tutorial_url,
        modalVideo: modal,
        closeModal: () => {
            setModal(false);
        },
        // video: "81db6ab30a",
    };

    function clickOutModal(e: React.MouseEvent<HTMLElement>) {
        const element = (e.target as HTMLElement).dataset;

        if (element?.closemodal) {
            setModal(false);
        }
    }

    return (
        <Container>
            {loading && (
                <div className="loader">
                    <Loader height={400} width={400} />
                </div>
            )}

            <div className="container-tabs">
                <div className={`tab ${tabs[0].active ? "active" : ""}`}>
                    {voucher
                        ? voucher?.voucher?.map((voucher) => (
                              <Cards {...voucher} />
                          ))
                        : null}
                </div>

                <div className={`tab ${tabs[1].active ? "active" : ""}`}>
                    <ComoUsar />
                </div>
            </div>

            <Modal
                onClick={clickOutModal}
                component={ModalVideo}
                active={modal}
                rest={configVideo}
            />
        </Container>
    );
}

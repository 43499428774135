import { Text } from "aplication-yetz";
import { BoxCatalogoStyles } from "../../styles";
import { Combo } from "../../../../Hook/useCombo";

interface ComboProps extends Combo {
    itensAtivos: number[];
    toggleItem(itemId: number): void;
}

export function Card(props: ComboProps) {
    const { itensAtivos } = props;
    return (
        <BoxCatalogoStyles
            isSelected={itensAtivos.includes(props.id)}
            onClick={() => props.toggleItem(props.id)}
        >
            <div className="container-img">
                <img src={props.foto_capa} alt="" />
            </div>

            <div className="container-text">
                <Text fontSize="md" textTransform="uppercase">
                    {props.id}
                </Text>
                <Text fontSize="md" textTransform="uppercase">
                    {props.nome}
                </Text>
            </div>
        </BoxCatalogoStyles>
    );
}

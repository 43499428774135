import { Filter, Box } from "aplication-yetz";
import { Catalogo } from "../../Hook/usePlataforma";

import { SubHeaderStyles } from "./styles";
import { ChangeEvent } from "react";

interface SubHeaderProps {
    setFilter: (filter: string) => void;
    setModalAtivacao: (modal: boolean) => void;
    catalogosIds: number[];
    catalogo: Catalogo[];
    estoque: boolean;
    status: boolean;
}

export function SubHeader(props: SubHeaderProps) {
    const { setFilter } = props;

    return (
        <SubHeaderStyles>
            <Box pr="55px">
                <Filter
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setFilter(e.target.value)
                    }
                />
            </Box>
        </SubHeaderStyles>
    );
}

import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .container-img {
        display: flex;
        align-items: start;
        img {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            object-fit: cover;
        }
    }

    .container-header {
        margin-left: 14px;
        h3 {
            font-family: "Lato";
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            text-align: left;
            text-decoration-skip-ink: none;
            color: #5d5e5e;
            margin-bottom: 7px;
        }

        p {
            font-family: "Lato";
            font-size: 16px;
            font-weight: 700;
            line-height: 19.2px;
            text-align: left;
            text-decoration-skip-ink: none;
            color: #000;
        }
    }
`;

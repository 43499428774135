import Modal from "../NovoModal";
import { Header } from "./header";
import { TableCombo } from "./table";
import { FormAdd } from "./FormAdd";
import { Container } from "./styles";
import { useCombo } from "../../Hook/useCombo";

export function Combos() {
    const { modal, setModal, setFilter } = useCombo();

    function handleCloseModal() {
        setModal((prev) => !prev);
    }

    return (
        <Container>
            <Header setFilter={setFilter} setModal={handleCloseModal} />

            <TableCombo />

            <Modal open={modal} handleClose={handleCloseModal}>
                {modal ? <FormAdd /> : <></>}
            </Modal>
        </Container>
    );
}

import React, { createContext, useContext, useEffect, useState } from "react";

import { api } from "../services";
import { Categoria, Variacao } from "./useProduto";
import { Option } from "../types";
import { FormCombo } from "../components/Combos/FormAdd/types";
import { toast } from "react-toastify";

interface ComboData {
    categorias: Option[];
    produtos: Variacao[];
    onLoadModal(): Promise<void>;
    filterProdutos(value: string): Variacao[];

    setProdutosIdCombo: (value: React.SetStateAction<number[]>) => void;
    produtosIdCombo: number[];
    onLoading: boolean;

    modal: boolean;
    setModal: (value: React.SetStateAction<boolean>) => void;

    createCombo(data: FormCombo): Promise<void>;

    loading: boolean;
    combos: Combo[];
    filter: string;
    setFilter: (value: React.SetStateAction<string>) => void;

    updateCombo(values: FormCombo, id: number): Promise<void>;
}

interface ComboProvider {
    children: React.ReactNode;
}

export interface HistoryProps {
    id?: number;
}

export interface Categorias {
    categoria: Categoria;
    categoria_id: number;
    combo_id: number;
    created_at: string;
    id: number;
    updated_at: string;
}

export interface Combo {
    categorias: Categorias[];
    created_at: string;
    descricao: string;
    detalhes: string;
    estimativa: number;
    foto_capa: string;
    id: number;
    informacoes_importantes: string;
    informacoes_temporarias: string;
    nome: string;
    status: number;
    template_voucher_id: number;
    tipo: string;
    updated_at: string;
}

const Context = createContext<ComboData>({} as ComboData);

export function UseComboProvider(props: ComboProvider) {
    const { children } = props;
    const [categorias, setCategorias] = useState<Option[]>([]);
    const [produtos, setProdutos] = useState<Variacao[]>([]);
    const [combos, setCombos] = useState<Combo[]>([]);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [filter, setFilter] = useState("");
    const [produtosIdCombo, setProdutosIdCombo] = useState<number[]>([]);
    const [onLoading, setOnLoading] = useState(false);

    async function updateCombo(values: FormCombo, id: number) {
        const keys = Object.keys(values) as Array<keyof FormCombo>;
        const formData = new FormData();
        const removeKeys = ["produto_variacao", "categorias"];

        keys.forEach((key) => {
            if (!removeKeys.includes(key))
                formData.append(key, values[key] as string);
        });

        try {
            await api.post(`/combo/${id}`, formData);
            await getCombos();
            toast.success("Combo atualizado com sucesso");
            setModal(false);
        } catch (error) {
            console.log(error);
            throw new Error("Erro ao criar combo");
        }
    }

    async function createCombo(values: FormCombo) {
        const keys = Object.keys(values) as Array<keyof FormCombo>;
        const formData = new FormData();
        const removeKeys = ["produto_variacao", "categorias"];

        keys.forEach((key) => {
            if (!removeKeys.includes(key))
                formData.append(key, values[key] as string);
        });

        for (let i = 0; i < values.produto_variacao.length; i++) {
            formData.append(
                "produtos_variacao[]",
                values.produto_variacao[i].toString()
            );
        }

        for (let i = 0; i < values.categorias.length; i++) {
            formData.append("categorias[]", values.categorias[i].toString());
        }

        try {
            await api.post("/combo", formData);
            await getCombos();
            toast.success("Combo criado com sucesso");
            setModal(false);
        } catch (error) {
            console.log(error);
            throw new Error("Erro ao criar combo");
        }
    }

    async function getCombos() {
        try {
            setLoading(true);
            const { data } = await api.get("/combo");
            setLoading(false);
            setCombos(data);
        } catch (error) {
            setLoading(false);
            return [] as Combo[];
        }
    }

    async function getCategoria() {
        try {
            const { data } = await api.get("/categoria");
            return data;
        } catch (error) {
            console.log(error);
            return [] as Categoria[];
        }
    }

    async function getProdutos() {
        try {
            const { data } = await api.get<Variacao[]>("/produto/variacao");
            return data as Variacao[];
        } catch (error) {
            console.log(error);
            return [] as Variacao[];
        }
    }

    async function onLoadModal() {
        try {
            setOnLoading(true);
            const [produtos, categorias] = await Promise.all([
                getProdutos(),
                getCategoria(),
            ]);
            setCategorias(createOptions(categorias));
            setProdutos(produtos);
            setOnLoading(false);
        } catch (error) {
            setOnLoading(false);
            console.log(error);
            throw new Error("Erro ao carregar dados");
        }
    }

    function filterProdutos(value: string) {
        if (value === "") return produtos;

        return produtos.filter((produto) =>
            JSON.stringify(produto, ["nome"])
                .toLowerCase()
                .includes(value.toLowerCase())
        );
    }

    function createOptions(data: any[]) {
        return data.map((item: Categoria) => ({
            value: item.id,
            label: item.nome,
        }));
    }

    useEffect(() => {
        getCombos();
    }, []);

    return (
        <Context.Provider
            value={{
                categorias,
                onLoadModal,
                onLoading,
                produtos,
                filterProdutos,
                produtosIdCombo,
                setProdutosIdCombo,
                createCombo,
                modal,
                setModal,
                combos,
                loading,
                filter,
                setFilter,
                updateCombo,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useCombo() {
    const context = useContext(Context);

    return context;
}

import { Box, Text } from "aplication-yetz";
import { RegrasDetalheProdutoProps } from "./types";

export function RegrasDetalheProduto(props: RegrasDetalheProdutoProps) {
    const { texto, titulo } = props;
    return (
        <Box>
            <Text mb="8px" bold={true}>
                {titulo}
            </Text>
            <div
                dangerouslySetInnerHTML={{
                    __html: texto || "",
                }}
            ></div>
        </Box>
    );
}

import { Box, Filter, Flex, TextImg } from "aplication-yetz";

import imgEditar from "../../../../assets/sistema/card-editar.svg";
import { Container } from "./styles";

export function Header() {
    return (
        <Container>
            <Flex justifyContent="space-between" mb="64px">
                <Box>
                    <TextImg
                        fontSize="mdl"
                        img={imgEditar}
                        bold={true}
                        pl="24px"
                    >
                        Produtos
                    </TextImg>
                </Box>

                <Box ml="auto" mr="auto">
                    <Filter />
                </Box>
            </Flex>
        </Container>
    );
}

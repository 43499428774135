import styled from "styled-components";

export const Container = styled.div`
    margin-bottom: 27px;
    .container-header-back {
        margin-bottom: 28px;
        button {
            display: flex;
            align-items: center;
            .container-img {
                display: flex;
                align-items: start;
                margin-right: 6px;
            }
            .container-text {
                p {
                    font-family: "Montserrat";
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 14.63px;
                    text-align: center;
                    color: #5e5f5f;
                }
            }
        }
    }

    .container-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 27px;
        &:last-child {
            button {
                margin-left: 8px;
            }
        }
        .container-img {
            display: flex;
            align-items: start;
        }
        button,
        small {
            font-family: "Lato";
            font-size: 14px;
            font-weight: bold;
            line-height: 16.8px;
            text-align: center;
            color: #f17e21;
            margin-left: 3px;
        }
    }

    .container-video {
        button {
            width: 100%;
            height: 41px;
            padding: 12px 25px 12px 25px;
            border-radius: 20px;
            background: #f17e21;

            font-family: "Lato";
            font-size: 14px;
            font-weight: 700;
            line-height: 16.8px;
            text-align: center;
            color: #ffffff;
        }
    }
`;

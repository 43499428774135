import { ModalStyles } from "./styles";
import { Tutorial } from ".";
import imgCard from "../../assets/sistema/instrucoes.png";

import { Selos } from "./selos";
import { regexTagStyles } from "../../config/regex";

//replace(/background-color\s*:[^";]*|background\s*:[^";]*/g, '')
interface ModalProps {
    rest: {
        id: number;
        tutorial: string;
        video: string;
        img: string;
        tutorialObservacao: string;
        onClose: () => void;
        openModalVideo: () => void;
        dados: Tutorial;
        produto_id?: number;
        template_id?: number;
    };
}

export function ModalTutorial(props: ModalProps) {
    const { rest } = props;
    const hasCupom = rest.dados?.cupom && rest.dados.cupom;

    const arrTutorial = rest.tutorial ? JSON.parse(rest.tutorial) : [];
    
    const template: { [key: string]: string } = {
        "17": "stone",
    };

    const templateStyle = rest.template_id
        ? template[String(rest.template_id)]
        : "";

    return (
        <ModalStyles className={`${templateStyle ? templateStyle : ""}`}>
            <div className="container-btn">
                {!rest.id ? (
                    <button
                        onClick={() => {
                            if (props.rest.onClose) {
                                props.rest.onClose();
                            }
                        }}
                    ></button>
                ) : null}
            </div>

            <div className="content-header-modal">
                <div className="text">
                    <span>
                        Como usar {templateStyle ? "seu" : ""}
                        {templateStyle ? (
                            <strong> voucher digital</strong>
                        ) : (
                            <></>
                        )}
                    </span>
                </div>
            </div>

            <div className="container-instrucoes">
                <div className="instrucoes">
                    <img
                        src={rest.img ? rest.img : imgCard}
                        alt=""
                        className="logo"
                    />
                    {rest.video ? (
                        <button
                            onClick={() => {
                                if (props.rest.openModalVideo) {
                                    props.rest.openModalVideo();
                                }
                            }}
                        >
                            {/* <img src={imgPlayer} alt="" /> */}
                            <div className="player-yetz"></div>
                            ASSISTIR VÍDEO
                        </button>
                    ) : null}

                    <p>
                        Abra o link do seu {hasCupom ? "cupom" : "voucher"} e
                        copie <strong>o código recebido</strong>
                    </p>
                </div>
            </div>

            <div className="container-cards">
                <div className="cards">
                    {rest.tutorialObservacao ? (
                        <div className="card-atencao atencao">
                            <h4>ATENÇÃO</h4>
                            <p>{rest.tutorialObservacao}</p>
                        </div>
                    ) : null}

                    {Array.isArray(arrTutorial)
                        ? arrTutorial.map((item, key) => {
                              return (
                                  <div className="card-atencao">
                                      <small>{key + 1}º PASSO </small>
                                      <div
                                          dangerouslySetInnerHTML={{
                                              __html: item
                                                  .trim()
                                                  .replace(
                                                      /(?:|[^"]*[;\s])(background-color\s*:[^";]*)|(?:|[^"]*[;\s])(background\s*:[^";]*)/g,
                                                      ""
                                                  ),
                                          }}
                                      ></div>
                                  </div>
                              );
                          })
                        : null}

                    <div className="container-informacoes">
                        <div className="container-esq">
                            <div className="item">
                                {rest?.dados && (
                                    <Selos
                                        templateId={
                                            rest.dados.template_voucher_id
                                        }
                                        selo={rest.dados.selo}
                                        idVariacao={
                                            rest.produto_id
                                                ? rest.produto_id
                                                : rest.dados.produto_id
                                        }
                                        link={rest.dados.external_link}
                                    />
                                )}
                            </div>

                            {rest?.dados?.external_link_label && (
                                <div className="item">
                                    <button
                                        onClick={() => {
                                            if (rest?.dados?.external_link) {
                                                window.open(
                                                    rest?.dados?.external_link,
                                                    "_blank"
                                                );
                                            }
                                        }}
                                    >
                                        <span>
                                            {rest?.dados?.external_link_label}
                                        </span>
                                    </button>
                                </div>
                            )}

                            {rest?.dados?.descricao && (
                                <div className="item descricao">
                                    <h3>Descrição do produto</h3>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                rest?.dados?.descricao.replace(
                                                    regexTagStyles,
                                                    ""
                                                ) || "",
                                        }}
                                    ></p>
                                </div>
                            )}

                            {rest?.dados?.informacoes_importantes && (
                                <div className="item info">
                                    <h3>Informações importantes</h3>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                rest?.dados?.informacoes_importantes.replace(
                                                    regexTagStyles,
                                                    ""
                                                ) || "",
                                        }}
                                    ></p>
                                </div>
                            )}
                        </div>

                        <div className="container-dir">
                            {rest?.dados?.detalhes && (
                                <div className="item">
                                    <h3>Regras de utilização</h3>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                rest?.dados?.detalhes.replace(
                                                    regexTagStyles,
                                                    ""
                                                ) || "",
                                        }}
                                    ></p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </ModalStyles>
    );
}

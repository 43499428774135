import { DetalheCombo } from "../../components/DetalheCombo";
import { UseDetalheComboProvider } from "../../Hook/useDetalheCombo";

export function PageComboDetalhe() {
    return (
        <UseDetalheComboProvider>
            <DetalheCombo />
        </UseDetalheComboProvider>
    );
}

import { Box, Flex, Text, TextImg } from "aplication-yetz";
import { Goback } from "../../Goback";

import detalheProdutoImg from "../../../assets/sistema/menu-produtos.svg";

import { Container } from "./styles";
import { Toggle } from "../../Toggle";
import { useDetalheCombo } from "../../../Hook/useDetalheCombo";
import { FormCombo } from "../FormEdit/types";

export function Header() {
    const { detalheCombo, setModal, updateCombo } = useDetalheCombo();

    return (
        <Container>
            <Goback title={detalheCombo.nome} />
            <Flex ml="auto">
                <Box mr="67px">
                    <Toggle
                        active={detalheCombo.status === 1}
                        titulo={<Text bold={true}>Disponibilidade</Text>}
                        loading={false}
                        onClick={async () =>
                            await updateCombo({
                                status: detalheCombo.status === 1 ? 0 : 1,
                            } as FormCombo)
                        }
                    />
                </Box>

                <Box>
                    <button onClick={() => setModal((prev) => !prev)}>
                        <TextImg
                            className="detalhe-produto"
                            img={detalheProdutoImg}
                            color="primary"
                            bold={true}
                        >
                            Editar dados do Combo
                        </TextImg>
                    </button>
                </Box>
            </Flex>
        </Container>
    );
}

import { Formik, Field, Form, ErrorMessage, FormikProps } from "formik";
import { Box, ButtonSystem, Flex, Text, TextImg } from "aplication-yetz";
import { FormCombo } from "./types";
import { addSchema, initialValues } from "./config";
import { ContainerImg, Container, TextAreaStyles } from "./styles";
import imgCamera from "../../../assets/sistema/camera.svg";
import fecharModalImg from "../../../assets/sistema/fechar-modal.svg";
import { Input } from "../../Input";
import { useCombo } from "../../../Hook/useCombo";
import { MultipleSelect, Select } from "../../Select";
import { Toggle } from "../../Toggle";
import { Option } from "../../Select/types";
import { useEffect, useMemo } from "react";
import { DrafEditor } from "../../Editor";
import { Combos } from "./combos";

export function FormAdd() {
    const { onLoadModal, categorias, setModal, createCombo } = useCombo();
    const templateVoucher: Option[] = useMemo(() => {
        return [
            {
                value: 1,
                label: "Padrão",
            },
        ];
    }, []);

    useEffect(() => {
        onLoadModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={addSchema}
                onSubmit={createCombo}
            >
                {(props: FormikProps<FormCombo>) => {
                    const { values, setFieldValue } = props;
                    const { status } = values;
                    const url =
                        typeof values.foto_capa === "string" &&
                        values.foto_capa !== ""
                            ? values.foto_capa
                            : values.foto_capa
                            ? URL.createObjectURL(values.foto_capa)
                            : null;

                    return (
                        <Form>
                            <ContainerImg
                                alignItems="start"
                                justifyContent="space-between"
                            >
                                <Box position="relative">
                                    {url ? (
                                        <>
                                            <img
                                                className="file"
                                                src={url}
                                                alt=""
                                            />

                                            <button
                                                className="remover-img"
                                                type="button"
                                                onClick={() =>
                                                    setFieldValue(
                                                        "foto_capa",
                                                        ""
                                                    )
                                                }
                                            >
                                                <img
                                                    src={fecharModalImg}
                                                    alt=""
                                                />
                                            </button>
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    <label htmlFor="file">
                                        <TextImg
                                            mt="39px"
                                            color="primary"
                                            className="text-img"
                                            img={imgCamera}
                                        >
                                            Atualizar imagem
                                        </TextImg>
                                    </label>
                                    <ErrorMessage
                                        component="small"
                                        name="foto_capa"
                                    />
                                    <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        accept="image/png, image/gif, image/jpeg"
                                        onChange={(e) => {
                                            setFieldValue(
                                                "foto_capa",
                                                e.target.files?.length
                                                    ? e.target.files[0]
                                                    : null
                                            );
                                        }}
                                    />
                                </Box>

                                <Box>
                                    <button
                                        onClick={() =>
                                            setModal((prev) => !prev)
                                        }
                                        type="button"
                                    >
                                        <img src={fecharModalImg} alt="" />
                                    </button>
                                </Box>
                            </ContainerImg>

                            <div className="container-form">
                                <Field
                                    component={Input}
                                    name="nome"
                                    label="NOME DO COMBO"
                                />
                                <ErrorMessage component="small" name="nome" />
                            </div>

                            <div className="container-form">
                                <MultipleSelect
                                    placeholder="CATEGORIA"
                                    onChange={(item) => {
                                        setFieldValue("categorias", [
                                            ...item.map((item) => item.value),
                                        ]);
                                    }}
                                    options={categorias}
                                />
                                <ErrorMessage
                                    component="small"
                                    name="categorias"
                                />
                            </div>

                            <div className="container-form status">
                                <Flex
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Text color="gray300">STATUS</Text>
                                    <Toggle
                                        titulo="Ativo"
                                        active={status ? true : false}
                                        onClick={() =>
                                            setFieldValue(
                                                "status",
                                                !status ? 1 : 0
                                            )
                                        }
                                    />
                                </Flex>
                            </div>

                            <Field
                                component={Combos}
                                name="combo"
                                label="NOME DO COMBO"
                            />
                            <ErrorMessage component="small" name="combo" />

                            <Box
                                className="container-form"
                                mb="32px !important"
                            >
                                <Select
                                    placeholder="TEMPLATE TELA VOUCHER"
                                    onChange={(item) =>
                                        setFieldValue(
                                            "template_voucher_id",
                                            item.value
                                        )
                                    }
                                    options={templateVoucher}
                                />
                                <ErrorMessage
                                    component="small"
                                    name="template_voucher_id"
                                />
                            </Box>

                            <TextAreaStyles>
                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Text as="p">Descrição</Text>
                                </Flex>
                            </TextAreaStyles>

                            <DrafEditor
                                onChange={(value) => {
                                    setFieldValue("descricao", value);
                                }}
                            />
                            <ErrorMessage component="small" name="descricao" />

                            <TextAreaStyles>
                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Text as="p">Informações importantes</Text>
                                </Flex>
                            </TextAreaStyles>

                            <DrafEditor
                                onChange={(value) => {
                                    setFieldValue(
                                        "informacoes_importantes",
                                        value
                                    );
                                }}
                            />
                            <ErrorMessage
                                component="small"
                                name="informacoes_importantes"
                            />

                            <TextAreaStyles>
                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Text as="p">Informações Temporárias</Text>
                                </Flex>
                            </TextAreaStyles>

                            <DrafEditor
                                onChange={(value) => {
                                    setFieldValue(
                                        "informacoes_temporarias",
                                        value
                                    );
                                }}
                            />
                            <ErrorMessage
                                component="small"
                                name="informacoes_temporarias"
                            />

                            <TextAreaStyles>
                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Text as="p">Regras de Utilização</Text>
                                </Flex>
                            </TextAreaStyles>

                            <DrafEditor
                                onChange={(value) => {
                                    setFieldValue("detalhes", value);
                                }}
                            />
                            <ErrorMessage component="small" name="detalhes" />

                            <ButtonSystem
                                width={246}
                                height={56}
                                heightSpiner={30}
                                widthSpnier={30}
                                loading={props.isSubmitting}
                                type="submit"
                            >
                                SALVAR ALTERAÇÕES
                            </ButtonSystem>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
}

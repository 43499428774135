import { Container } from "./styles";
import imgCopiar from "../../../../assets/sistema/copiar-combo.svg";
import { copy } from "../../../../util/helper";

interface CodigoProps {
    code: string;
}

export function Codigo(props: CodigoProps) {
    return (
        <Container>
            <div className="container-text">
                <h3>Codigo</h3>
                <p>{props.code}</p>
            </div>

            <button onClick={() => copy(props.code)}>
                <img src={imgCopiar} alt="Copiar" />
            </button>
        </Container>
    );
}

import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 278px;
    margin: 0 auto;

    .line {
        width: 1.5px;
        height: 14px;
        background: #e8e8e8;
        margin: 8px 0;
    }

    .container-btn {
        display: flex;
        align-items: center;
        &:last-child {
            button {
                margin-left: 8px;
            }
        }
        .container-img {
            display: flex;
            align-items: start;
        }
        button {
            font-family: "Lato";
            font-size: 14px;
            font-weight: 700;
            line-height: 16.8px;
            text-align: center;
            color: #f17e21;
            margin-left: 3px;
        }
    }
`;

import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";

import { api } from "../services";

interface ProdutoData {
    store: (values: FormData) => Promise<AxiosResponse>;
    storePassos: (values: FormData, id: number) => Promise<AxiosResponse>;
    update: (values: FormData, id: number) => Promise<AxiosResponse>;
    updateStatusProduto: (
        values: UpdateVariacao,
        id: number
    ) => Promise<AxiosResponse>;

    produto: DataProdutos;
    showProduto: Produto;
    loadingProduto: boolean;

    categoria: Categoria[];

    getCategoria: () => Promise<Categoria[]>;
    storeCategoria: (values: FormCategoria) => Promise<AxiosResponse>;
    updateCategoria: (
        values: FormCategoria,
        id: number
    ) => Promise<AxiosResponse>;
    excluirCategoria: (id: string) => void;

    updateVariacao: (
        value: UpdateVariacao,
        id: number
    ) => Promise<AxiosResponse>;

    storeVariacao: (value: UpdateVariacao) => Promise<AxiosResponse>;
    deleteVariacao: (id: number) => Promise<AxiosResponse>;

    getProdutos: (search?: string, page?: string) => void;
    AddItemGaleria(file: FileList): Promise<void>;
    RemoveItemGaleria(): Promise<void>;
    UpdateItemGaleria(): Promise<void>;
    galeria: Galeria[];

    galeriaItemId: string | null;
    setGaleriaItemId: (value: string | null) => void;
    fileImgProduto: FileList | null;
    setFileImgProduto: (value: FileList | null) => void;
    alertDialog: boolean;
    setAlertDialog: (value: boolean) => void;
}

interface ProdutoProvider {
    children: React.ReactNode;
}

export interface DataProdutos {
    data: Produto[];
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string | null;
    per_page: number;
    prev_page_url: string | null;
    to: number;
    total: number;
}

export interface Produto {
    id: number;
    nome: string;
    tipo: string;
    status: number;
    descricao: string;
    detalhes: string;
    foto_capa: string;
    url_tutorial: string;
    variacoes: Variacao[];
    categorias: Categorias[];
    desconto_outlet: string;
    data_outlet: string;
    informacoes_temporarias: string;
    informacoes_importantes: string;
    selo: string;
    alerta_estoque: string;
    template: string;
    template_voucher_id: string;
    template_validade_fixa: number;
    validade: string;
    tutorial_passos: string;
    tutorial_observacao: string;
    tutorial_url: string;
    external_link: string;
    external_link_label: string;
    gallery: Galeria;
    cupom: string;
}

interface Galeria {
    [key: string]: string;
}

interface Metas {
    id: number;
    produto_variacao_id: number;
    fornecedor_id: number;
    key: string;
    created_at: string;
    updated_at: string;
}

export interface Variacao {
    id: number;
    produto_id: number;
    nome: string;
    status: number;
    valor_yetz: number;
    valor_reais: string;
    alerta_estoque: number | string;
    total_disponivel: number;
    status_alerta_estoque: number;
    tipo: string;
    produto?: Produto;
    metas?: Metas[];
}

interface FormCategoria {
    descricao: string;
    nome: string;
}

interface Categorias {
    categoria_id: number;
    created_at: string;
    id: number;
    produto_id: number;
    updated_at: string;
    categoria: Categoria;
}

export interface Categoria {
    created_at: string;
    descricao: string;
    id: number;
    categoria_id: number;
    logo: string;
    nome: string;
    ordem: number;
    slug: string;
    updated_at: string;
}

export interface UpdateVariacao {
    nome?: string;
    status?: number;
    valor_yetz?: string | number | null;
    valor_reais?: string | number | null;
    alerta_estoque?: string;
    produto_id?: number;
    status_alerta_estoque?: number;
}

export interface HistoryProps {
    id?: number;
}

interface Galeria {
    id: string;
    img: string;
}

const ProdutoContext = createContext<ProdutoData>({} as ProdutoData);

export function UseProdutoProvider(props: ProdutoProvider) {
    const { children } = props;
    const history = useHistory<HistoryProps>();

    const [produto, setProduto] = useState<DataProdutos>({} as DataProdutos);
    // const [produto, setProduto] = useState<Produto[]>([]);
    const [showProduto, setShowProduto] = useState<Produto>({} as Produto);

    const [loadingProduto, setLoadingProduto] = useState<boolean>(false);

    const [categoria, setCategoria] = useState<Categoria[]>([]);
    const [fileImgProduto, setFileImgProduto] = useState<FileList | null>(null);
    const [galeria, setGaleria] = useState<Galeria[]>([]);
    const [galeriaItemId, setGaleriaItemId] = useState<string | null>(null);
    const [alertDialog, setAlertDialog] = useState(false);

    async function store(values: FormData) {
        const headers = {
            "Content-Type": "multipart/form-data",
        };

        try {
            const response = await api.post<Produto>("/produto", values, {
                headers,
            });
            // const { data } = response;
            // setProduto([...produto, data]);

            return response;
        } catch (error: any) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
            return error;
        }
    }

    async function storeCategoria(values: FormCategoria) {
        try {
            const response = await api.post<Categoria>("/categoria", {
                ...values,
            });
            // const { data } = response;
            // setProduto([...produto, data]);

            return response;
        } catch (error: any) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
            return error;
        }
    }

    async function storePassos(values: FormData, id: number) {
        const headers = {
            "Content-Type": "multipart/form-data",
        };

        try {
            const response = await api.post<Produto>(`/produto/${id}`, values, {
                headers,
            });

            toast.success("Passos cadastrados com sucesso");

            show(id);

            return response;
        } catch (error: any) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
            return error;
        }
    }

    async function update(values: FormData, id: number) {
        const headers = {
            "Content-Type": "multipart/form-data",
        };

        try {
            const response = await api.post<Produto>(`/produto/${id}`, values, {
                headers,
            });
            const { data } = response;

            const galeria = data.gallery
                ? Object.keys(data.gallery).map((item) => {
                      return {
                          id: item,
                          img: data.gallery[item],
                      };
                  })
                : [];

            setGaleria(galeria);

            setShowProduto({ ...showProduto, ...data });

            return response;
        } catch (error: any) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
            return error;
        }
    }

    async function updateCategoria(values: FormCategoria, id: number) {
        try {
            const response = await api.post<Categoria>(`/categoria/${id}`, {
                ...values,
            });
            // const { data } = response;

            // setShowProduto({ ...showProduto, ...data });

            return response;
        } catch (error: any) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
            return error;
        }
    }

    async function show(id: number) {
        try {
            const { data } = await api.get<Produto>(`/produto/${id}`);
            const galeria = data.gallery
                ? Object.keys(data.gallery).map((item) => {
                      return {
                          id: item,
                          img: data.gallery[item],
                      };
                  })
                : [];

            setGaleria(galeria);
            setShowProduto(data);
        } catch (error: any) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
            return error;
        }
    }

    async function updateStatusProduto(values: UpdateVariacao, id: number) {
        try {
            const response = await api.post<Produto>(`/produto/${id}`, {
                ...values,
            });

            const { data } = response;

            setShowProduto({ ...showProduto, ...data });

            return response;
        } catch (error: any) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
            return error;
        }
    }

    async function storeVariacao(value: UpdateVariacao) {
        try {
            const response = await api.post<Variacao>(`/produto/variacao`, {
                ...value,
            });

            const { data } = response;

            setShowProduto({
                ...showProduto,
                variacoes: [...showProduto.variacoes, data],
            });

            toast.success("Variacao cadastrada com sucesso");

            return response;
        } catch (error: any) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");

            return error;
        }
    }

    async function updateVariacao(value: UpdateVariacao, id: number) {
        // const { valor_reais, valor_yetz, ...rest } = value;

        try {
            const response = await api.post<Variacao>(
                `/produto/variacao/${id}`,
                {
                    ...value,
                }
            );

            const { data } = response;

            const updateProduto: Produto[] = produto.data?.map(
                (prod: Produto, key: number) => {
                    const variacoes = prod.variacoes.map((item) => {
                        if (item.id === data.id) {
                            return data;
                        } else {
                            return item;
                        }
                    });

                    return { ...prod, variacoes };
                }
            );

            if (Object.values(showProduto).length > 0) {
                setShowProduto({
                    ...showProduto,
                    variacoes: [
                        ...showProduto.variacoes.map((item) =>
                            item.id === data.id ? data : item
                        ),
                    ],
                });
            } else {
                setProduto({ ...produto, data: updateProduto });
            }

            toast.success("Variacao atualizada com sucesso");
            return response;
        } catch (error: any) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
            return error;
        }
    }

    async function deleteVariacao(id: number) {
        try {
            const response = await api.delete(`/produto/variacao/${id}`);

            const updateProduto: Produto[] = produto.data?.map(
                (prod: Produto, key: number) => {
                    const variacoes = prod.variacoes.filter(
                        (item) => item.id !== id
                    );

                    return { ...prod, variacoes };
                }
            );

            if (Object.values(showProduto).length > 0) {
                console.log(
                    showProduto.variacoes.filter((item) => item.id !== id)
                );

                setShowProduto({
                    ...showProduto,
                    variacoes: showProduto.variacoes.filter(
                        (item) => item.id !== id
                    ),
                });
            } else {
                setProduto({ ...produto, data: updateProduto });
            }

            toast.success("Variacao deletada com sucesso");
            return response;
        } catch (error: any) {
            toast.error("ops algo de errado aconteceu");
            console.log(error);

            return error;
        }
    }

    async function getCategoria() {
        try {
            const { data } = await api.get("/categoria");
            setCategoria(data);
            return data;
        } catch (error) {
            console.log(error);
            return error as Categoria[];
        }
    }

    async function excluirCategoria(id: string) {
        try {
            const response = await api.delete(`categoria/${id}`);

            toast.success("Categoria excluida com sucesso");

            return response;
        } catch (error: any) {
            toast.error("Ops algo de errado aconteceu ");
            return error;
        }
    }

    async function getProdutos(search?: string, page?: string) {
        setLoadingProduto(true);
        try {
            const params: Record<string, string> = {};

            if (search?.length) {
                params["search"] = search;
            }

            if (page?.length) {
                params["page"] = page;
            }

            const { data } = await api.get<DataProdutos>("/produto", {
                params,
            });
            setProduto(data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingProduto(false);
        }
    }

    async function AddItemGaleria(file: FileList) {
        try {
            const formData = new FormData();

            const arrIdImgs = galeria.map((item) => item.id);

            for (let i = 0; i < file.length; i++) {
                formData.append("gallery_[]", file[i]);
            }

            for (let i = 0; i < arrIdImgs.length; i++) {
                formData.append("gallery[]", arrIdImgs[i]);
            }

            await update(formData, showProduto.id);
        } catch (error) {
            toast.error("Algo de errado aconteceu");
            console.log(error);
        }
    }

    async function RemoveItemGaleria() {
        try {
            if (!galeriaItemId) {
                toast.error("Item não encontrado");
                return;
            }

            const updateGalery = galeria.filter(
                (item) => item.id !== galeriaItemId
            );
            const formData = new FormData();

            for (let i = 0; i < updateGalery.length; i++) {
                formData.append("gallery[]", updateGalery[i].id);
            }

            await update(formData, showProduto.id);
            setFileImgProduto(null);
            toast.success("Sucesso!");
        } catch (error) {
            toast.error("Algo de errado aconteceu");
            console.log(error);
        }
    }

    async function UpdateItemGaleria() {
        try {
            if (!galeriaItemId || !fileImgProduto?.length) {
                toast.error("Item não encontrado");
                return;
            }
            const formData = new FormData();

            const arrIdImgs = galeria.filter(
                (item) => item.id !== galeriaItemId
            );

            for (let i = 0; i < fileImgProduto?.length; i++) {
                formData.append("gallery_[]", fileImgProduto[i]);
            }

            for (let i = 0; i < arrIdImgs.length; i++) {
                formData.append("gallery[]", arrIdImgs[i].id);
            }

            await update(formData, showProduto.id);
            setFileImgProduto(null);
            toast.success("Sucesso!");
        } catch (error) {
            toast.error("Algo de errado aconteceu");
            console.log(error);
        }
    }

    useEffect(() => {
        if (history.location.state?.id) {
            show(history.location.state.id);
        }
    }, [history.location.state?.id]);

    return (
        <ProdutoContext.Provider
            value={{
                store,
                storePassos,
                update,
                produto,
                showProduto,
                updateStatusProduto,
                loadingProduto,
                galeria,
                galeriaItemId,
                setGaleriaItemId,
                fileImgProduto,
                setFileImgProduto,
                AddItemGaleria,
                RemoveItemGaleria,
                UpdateItemGaleria,
                categoria,
                alertDialog,
                setAlertDialog,

                getCategoria,
                storeCategoria,
                updateCategoria,
                excluirCategoria,

                storeVariacao,
                updateVariacao,
                deleteVariacao,
                getProdutos,
            }}
        >
            {children}
        </ProdutoContext.Provider>
    );
}

export function useProduto() {
    const context = useContext(ProdutoContext);

    return context;
}

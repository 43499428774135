import styled from "styled-components";

export const Container = styled.div``;

export const ImgContainer = styled.div`
    width: 76px;
    height: 50px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }
`;

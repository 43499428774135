import * as Yup from "yup";
import { FormCombo } from "./types";

export const initialValues: FormCombo = {
    nome: "",
    template_voucher_id: "",
    produto_variacao: [],
    foto_capa: "",
    status: 0,
    tipo: "",
    categorias: [],
    descricao: "",
    informacoes_importantes: "",
    informacoes_temporarias: "",
    detalhes: "",
};

export const addSchema = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório"),
    template_voucher_id: Yup.string().required("Campo obrigatório"),
    produto_variacao: Yup.array().min(1, "Campo obrigatório"),
    foto_capa: Yup.string().required("Campo obrigatório"),
    status: Yup.string(),
    tipo: Yup.string().required("Campo obrigatório"),
    categorias: Yup.array().of(Yup.number()),
    descricao: Yup.string(),
    informacoes_importantes: Yup.string(),
    informacoes_temporarias: Yup.string(),
    detalhes: Yup.string(),
});

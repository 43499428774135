import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { AxiosResponse } from "axios";
import { useHistory, NavLinkProps } from "react-router-dom";
import { api } from "../services";
import { Combo } from "./useCombo";
import { toast } from "react-toastify";

interface UsePlataformaData {
    plataforma: Plataforma[];
    combos: Combo[];
    loadingPataforma: boolean;

    catalogo: Catalogo[];
    updateCatalogo: (
        catalogo: UpdateCatalogoData,
        id?: number
    ) => Promise<AxiosResponse>;
    updateCombo(combos: number[]): Promise<void>;

    user: UserData;
    loadingUser: boolean;
}

interface UsePlataformaProviderProps {
    children: React.ReactNode;
}

interface Roles {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

export interface Plataforma {
    id: number;
    name: string;
    email: string;
    status: number;
    ["sem-estoque"]: boolean;
    ["pedido-outlet"]: boolean;
    roles: Roles[];
}

interface PlataformaData {
    plataforma: Plataforma[];
}

export interface Catalogo {
    id: number;
    nome: string;
    valor_yetz: number;
    valor_reais: number;
    produto: Produto;
    foto_capa: string;
}

export interface UpdateCatalogoData {
    ["sem-estoque"]?: number;
    status?: number;
    catalogo?: number[];
    ["pedido-outlet"]?: number;
}

interface Produto {
    categorias: Categorias[];
    foto_capa: string;
}

interface Categorias {
    categoria: {
        logo: string;
        nome: string;
    };
}

interface UserData {
    catalogo_id: Array<number>;
    catalogo_combo_id: Array<number>;
    created_at: string;
    email: string;
    id: number;
    name: string;
    status: number;
    ["sem-estoque"]: boolean;
}

const PlataformaContext = createContext<UsePlataformaData>(
    {} as UsePlataformaData
);

export function UsePlataformaProvider(props: UsePlataformaProviderProps) {
    const history = useHistory<NavLinkProps>();

    const [plataforma, setPlataforma] = useState<Plataforma[]>([]);
    const [loadingPataforma, setLoadingPataforma] = useState<boolean>(true);

    const [catalogo, setCatalogo] = useState<Catalogo[]>([]);
    const [combos, setCombos] = useState<Combo[]>([]);
    const [monitCatalogo, setMonitCatalogo] = useState<any>();

    const [user, setUser] = useState<UserData>({} as UserData);
    const [loadingUser, setLoadingUser] = useState<boolean>(true);

    const { children } = props;

    const getCatalogo = useCallback(async () => {
        try {
            const { data } = await api.get<Catalogo[]>(
                "/produto/variacao/catalogo"
            );
            return data;
        } catch (error) {
            console.log(error);
            return [] as Catalogo[];
        }
    }, []);

    const getUser = useCallback(async () => {
        try {
            const { data } = await api.get<UserData>(
                `/usuario/${history?.location?.state?.id}`
            );
            return data;
        } catch (error) {
            console.log(error);
            return {} as UserData;
        }
    }, [history?.location?.state?.id]);

    const getCombos = useCallback(async () => {
        try {
            const { data } = await api.get<Combo[]>(`/combo`);
            return data;
        } catch (error) {
            console.log(error);
            return [] as Combo[];
        }
    }, []);

    const onLoadDetalhePlataforma = useCallback(async () => {
        try {
            setLoadingUser(true);
            const [catalogos, user, combo] = await Promise.all([
                getCatalogo(),
                getUser(),
                getCombos(),
            ]);

            setCatalogo(catalogos);
            setUser(user);
            setCombos(combo);
            setLoadingUser(false);
        } catch (error) {
            setLoadingUser(false);
            throw new Error(
                "Não foi possível carregar os dados da plataforma. Tente novamente mais tarde."
            );
        }
    }, [getCatalogo, getCombos, getUser]);

    async function updateCatalogo(config: UpdateCatalogoData, id?: number) {
        try {
            const response = await api.post(
                `/usuario/${id ? id : history?.location?.state?.id}`,
                { ...config }
            );

            if (response.status === 200) {
                setMonitCatalogo(config);
            }

            return response;
        } catch (error: any) {
            console.log(error);
            return error;
        }
    }

    async function updateCombo(combos: number[]) {
        try {
            await api.post(`/usuario/${history?.location?.state?.id}`, {
                catalogo_combos: combos,
            });

            toast.success("Combos atualizados com sucesso!");
        } catch (error: any) {
            toast.error("Erro ao atualizar combos!");
        }
    }

    useEffect(() => {
        async function getPlataforma() {
            setLoadingPataforma(true);

            try {
                const { data } = await api.get<PlataformaData>("/usuario");
                setPlataforma(data.plataforma);
                setLoadingPataforma(false);
            } catch (error) {
                setLoadingPataforma(false);
                console.log(error);
            }
        }

        if (history.location.pathname === "/plataforma") {
            getPlataforma();
        }
    }, [history, monitCatalogo]);

    useEffect(() => {
        if (history.location.state?.id) {
            onLoadDetalhePlataforma();
        }
    }, [history.location.state?.id, onLoadDetalhePlataforma]);

    return (
        <PlataformaContext.Provider
            value={{
                plataforma,
                loadingPataforma,
                updateCombo,
                catalogo,
                updateCatalogo,
                combos,
                user,
                loadingUser,
            }}
        >
            {children}
        </PlataformaContext.Provider>
    );
}

export function usePlataforma() {
    const context = useContext(PlataformaContext);

    return context;
}

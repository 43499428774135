import { Box, Text, Flex } from "aplication-yetz";
import imgCopy from "../../../assets/sistema/copy.svg";
import { Container } from "./styles";
import { toast } from "react-toastify";

interface BoxCodigoProps {
    password?: string;
    code?: string;
    index: number;
}

export function BoxCodigo(props: BoxCodigoProps) {
    const { code, password, index } = props;
    function copy(value: string) {
        navigator.clipboard.writeText(value);
        toast.success(`Cupom copiado`);
    }

    let size = "mdl" as "mdl" | "md" | "xs";

    if ((code ?? "").length >= 15) {
        size = "md";
    }

    return (
        <Container password={!password ? "password" : ""}>
            <div className="codigo-vale">
                <Text
                    fontSize="xs"
                    textTransform="uppercase"
                    color="gray300"
                    as="small"
                >
                    código {index}
                </Text>
                <Flex>
                    <Text
                        fontSize={size}
                        fontWeight="bold"
                        marginRight="20px"
                        as="h4"
                    >
                        {code}
                    </Text>
                    <button onClick={() => code && copy(code)}>
                        <img src={imgCopy} alt="" />
                        copiar <br />
                        código
                    </button>
                </Flex>
            </div>

            {password && (
                <Box>
                    <Text
                        fontSize="xss"
                        textTransform="uppercase"
                        color="gray300"
                    >
                        Senha
                    </Text>

                    <Text fontSize="mdl" fontWeight="bold">
                        {password}
                    </Text>
                </Box>
            )}
        </Container>
    );
}

import styled from "styled-components";

export const Container = styled.div`
    background: #f7f7f7;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 10px;
    .container-index {
        width: 26px;
        p {
            font-size: 12px;
            font-weight: 700;
            line-height: 16.33px;
            letter-spacing: -0.04em;
            text-align: center;
            background: #5d5e5e;
            width: 100%;
            height: 26px;
            border-radius: 50%;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .container-detalhe {
        width: 226px;
        h4 {
            font-family: Lato;
            font-style: normal;
            font-weight: 800;
            font-size: 35px;
            line-height: 42px;
            text-align: center;
            color: ${({ theme }) => theme.colors.primary};
        }

        p {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 1rem;
            color: #5e5f5f;
        }

        small {
            background: ${({ theme }) => theme.colors.primary};
            border-radius: 25.6034px;

            font-family: Lato;
            font-style: normal;
            font-weight: 800;
            font-size: 1rem;
            line-height: 33px;
            padding: 0px 15px;
            /* Branco Yetz */

            color: ${({ theme }) => theme.colors.white};
            position: absolute;
            top: -18px;
            left: 23px;
        }
    }
`;
